<template>
  <div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <div style="font-size: 14px;">
        <sidebar-item
          v-for="route in permission_routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
          :default-active="activeMenu"
        />
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from '../Sidebar/SidebarItem'

export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters([
      'permission_routes',
      'sidebar'
    ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      // console.log(path, 'path================')
      // console.log(meta, 'meta================')
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }
  },
  created() {
    console.log(this.permission_routes, 'permission_routes=======')
  }
}
</script>
<style lang="scss" scoped>

</style>
