const state = {
  ossSTS: null
}

const mutations = {
  SET_OSS_STS: (state, ossSTS) => {
    state.ossSTS = ossSTS
  }
}

const actions = {
  ossSTS({ commit }, ossSTS) {
    commit('SET_OSS_STS', ossSTS)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
