/*
import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 * @returns {*}
 *
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles && route.meta.roles.length > 0) {
    return roles.some(role => route.meta.rolles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes
 * @param roles
 * @returns {Array}
 *
export function filterAsyncRoutes(routes, roles) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })
  return res
}

const state = {
  routes: []
}

const mutations = {
  SET_ROUTERS: (state, routes) => {
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  //  异步路由加载generateRoutes
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      let accessedRoutes
      if (roles.includes('admin')) {
        accessedRoutes = asyncRoutes || []
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      }
      commit('SET_ROUTERS', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
*/

import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles && route.meta.roles.length > 0) {
    // console.log(roles.some(role => route.meta.roles.includes(role)))
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutesgenerateRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    // console.log(tmp)
    // const result = hasPermission(roles, tmp)

    // console.log('filterAsyncRoutes is execute')
    // console.log(result)
    if (hasPermission(roles, tmp)) {
      // console.log('*************************2')
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })
  console.log(res)
  return res
}

const state = {
  routes: [],
  addRoutes: [],
  netRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
    // console.log(state.routes)
  },
  SET_NET_ROUTES: (state, routes) => {
    state.netRoutes = routes
    const arr = state.routes
    state.routes = arr.concat(routes)
    // console.log(state.routes)
  },
  ADD: (state, route) => {
    const routes = state.routes
    routes.push(route)
    state.routes = routes
  },
  REMOVE_ALL_ROUTES: (state) => {
    state.routes = []
    state.addRoutes = []
    state.netRoutes = []
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      let accessedRoutes
      if (roles.includes('admin')) {
        console.log('******************0')
        accessedRoutes = asyncRoutes || []
      } else {
        console.log('******************1')
        // console.log(roles)
        // console.log(asyncRoutes)
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
        // console.log(accessedRoutes)
      }
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },
  generateNetRoutes({ commit }, data) {
    return new Promise(resolve => {
      let accessedRoutes
      const netRoutes = data.routes
      const roles = data.roles
      // console.log(netRoutes)
      if (roles.includes('admin')) {
        accessedRoutes = netRoutes || []
      } else {
        accessedRoutes = filterAsyncRoutes(netRoutes, roles)
      }
      commit('SET_NET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },
  add({ commit }, route) {
    commit('ADD', route)
  },
  removeAllRoutes({ commit }) {
    commit('REMOVE_ALL_ROUTES')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
