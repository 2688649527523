import { getCountryList } from '@/api/demand.js' //   // 17个国家
import { industryList } from '@/api/industry' // 行业分类
const state = {
  showPiao: false,
  countryList: [], // 17个国家
  allIndustryList: [], // 行业分类
  // 审核状态
  processOption: [
    {
      id: 0,
      value: 0,
      label: '未通过',
      labelEn: 'Rejected'
    },
    {
      id: 1,
      value: 1,
      label: '已通过',
      labelEn: 'Accepted'
    },
    {
      id: 2,
      value: 2,
      label: '待审核',
      labelEn: 'Reviewing'
    }
  ],
  // 发布语言 1中文 2英文
  languageList: [
    {
      id: 1,
      value: '1',
      label: '中文',
      labelEn: 'Chinese'
    },
    {
      id: 2,
      value: '2',
      label: '英文',
      labelEn: 'English'
    }
  ],
  // 消息中心,已读和未读状态
  readOptions: [
    {
      value: true,
      label: '已读',
      labelEn: 'Read'
    },
    {
      value: false,
      label: '未读',
      labelEn: 'UnRead'
    }
  ]
}

const mutations = {
  SET_SHOW_PIAO: (state, show) => {
    state.showPiao = show
  },
  SET_COUNTRY_LIST: (state, value) => {
    state.countryList = value
  },
  SET_ALL_INDUSTRY_LIST: (state, value) => {
    state.allIndustryList = value
  },
  SET_PROCESS_OPTION: (state, value) => {
    state.processOption = value
  },
  SET_LANGUAGE_List: (state, value) => {
    state.languageList = value
  },
  SET_READ_OPTIONS: (state, value) => {
    state.readOptions = value
  }
}

const actions = {
  setShowPiao({ commit }, show) {
    commit('SET_SHOW_PIAO', show)
  },
  //  获取17个国家数据
  getAllCountryList({ commit }) {
    return new Promise((resolve) => {
      getCountryList().then((res) => {
        commit('SET_COUNTRY_LIST', res.data)
        resolve()
      })
    })
  },
  //  获取一级行业分类数据
  getIndustryList({ commit }) {
    return new Promise((resolve) => {
      industryList({ level: 1 }).then((res) => {
        commit('SET_ALL_INDUSTRY_LIST', res.data)
        resolve()
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
