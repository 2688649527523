import request from '@/utils/request'
import request2 from '@/utils/request2'

//  注册
export function register(data) {
  return request({
    url: '/api/lico/acMember/register',
    method: 'post',
    data: data
  })
}

//  注册校验邮箱(1可注册 0邮箱已使用)
export function emailVerify(params) {
  return request({
    url: '/api/lico/acMember/emailVerify',
    method: 'post',
    params: params
  })
}

// 校验密码格式
export function verifyPwd(params) {
  return request({
    url: '/api/lico/acMember/verifyPwd',
    method: 'get',
    params: params
  })
}

//
export function ipicRegister(data) {
  return request2({
    url: '/licojs2/browse/AcMemberController/register',
    method: 'post',
    data: data
  })
}

//  登录
export function login(data) {
  return request({
    url: '/api/member/login',
    method: 'post',
    data: data
  })
}

//  获取验证码
export function getCode(data) {
  return request({
    url: '/api/member/code',
    method: 'post',
    data: data
  })
}

//  忘记密码-用户校验
export function forgetPwdCheck(data) {
  return request({
    url: '/api/member/forgetPwd/userCheck',
    method: 'post',
    data: data
  })
}

//  忘记密码-重置密码
export function forgetPwdReset(data) {
  return request({
    // url: '/api/member/forgetPwd/pwdReset',
    url: '/api/member/forgetPwd/passUpdate',
    method: 'post',
    data: data
  })
}

//  单位类型
export function categoryList(params) {
  return request({
    url: '/api/member/category',
    method: 'get',
    params: params
  })
}

//  推荐人
export function refereesList() {
  return request({
    url: '/api/member/referees',
    method: 'get'
  })
}

//  国家
export function countryList(params) {
  return request({
    // url: '/api/member/country',
    url: '/api/cceec/country',
    method: 'get',
    params: params
  })
}

//  省
export function provinceList() {
  return request({
    url: '/api/member/province',
    method: 'get'
  })
}

//  市
export function cityList(params) {
  return request({
    url: '/api/member/city',
    method: 'get',
    params: params
  })
}

//  用户信息
export function getInfo() {
  return request({
    // url: '/api/member/infoDetail',
    url: '/api/lico/acMember/infoDetail',
    method: 'get'
  })
}

//  编辑资料
export function editInfo(data) {
  return request({
    // url: '/api/member/editInfo',
    url: '/api/lico/acMember/editInfo',
    method: 'post',
    data: data
  })
}

//  修改登录密码
export function updatePwd(data) {
  return request({
    url: '/api/member/updatePwd',
    method: 'put',
    data: data
  })
}

//  校验企业名称是否被注册
export function verifyOrgName(params) {
  return request({
    url: '/api/lico/acMember/verifyOrgName',
    method: 'get',
    params: params
  })
}

// 未读消息数
export function unreadNoticeNum() {
  return request({
    url: '/api/lico/acMember/unreadNoticeNum',
    method: 'get'
  })
}
