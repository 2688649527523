import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'

//  路由跳转
import './permission'
//  localStorage
import storage from './utils/storage'
//  通用常量
import constant from './utils/constant'
//  引入国际化
import i18n from './i18n/i18n'

Vue.prototype.$language = i18n.locale
Vue.prototype.$languageType = i18n.locale === 'cn' ? '1' : '2'

Vue.use(ElementUI)
Vue.use(storage)
Vue.use(constant)

Vue.config.productionTip = false

// import { setToken } from './utils/auth'
// setToken('hyanjeyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxODYwMjA1MzE3NEAxNjMuY29tIiwiZXhwIjoxNjg4Nzk4NDk0LCJjcmVhdGVkIjoxNjg4MTkzNjk0NjMzLCJ3ZWJTaXRlSWQiOiIyIn0.NYT0cobad9pk437gHPOtyDuUtQrmww1SyOeff6MG-bgjrNGMD1l5Y35jJ2BlqJ3EIA3YIK9m9F7Fzm3WR3Tm7Q')

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
