import Layout from '@/layout'

const state = {
  routers: []
}

const mutations = {
  SET_ROUTERS: (state, routers) => {
    state.routers = routers
  }
}

export function translateDataToTree(data) {
  const parents = data.filter(value => value.parentId === 'undefined' || value.parentId == null || value.parentId === 0)
  const children = data.filter(value => value.parentId !== 'undefined' && value.parentId != null || value.parentId !== 0)
  const translator = (parents, children) => {
    parents.forEach((parent) => {
      children.forEach((current, index) => {
        if (current.parentId === parent.id) {
          const temp = JSON.parse(JSON.stringify(children))
          temp.splice(index, 1)
          translator([current], temp)
          typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current]
        }
      })
    })
  }
  translator(parents, children)
  return parents
}

export function getComponent(route) {
  return import('@/views/home/index')
}

const actions = {
  getNetRoutes({ commit }, permissionList) {
    return new Promise(resolve => {
      const menus = []
      permissionList.forEach(function(item) {
        if (item.type !== 2) {
          menus.push(item)
        }
      })
      const routers = []
      for (const item of menus) {
        const roles = []
        if (item.perms && item.perms.length > 0) {
          roles.push(item.perms)
        }
        if (!item.parentId) {
          const router = {
            id: item.id,
            path: item.url,
            component: Layout,
            redirect: item.redirect,
            hidden: item.isBtnRoute,
            meta: { title: item.title, icon: item.icon, roles: roles },
            children: item.route ? [
              {
                path: '',
                hidden: item.isBtnRoute,
                // component: () => import('@/views/' + item.route),
                component: () => getComponent(item.route),
                meta: { title: item.title, icon: item.icon, roles: roles }
              }
            ] : []
          }
          routers.push(router)
        } else {
          const router = {
            id: item.id,
            parentId: item.parentId,
            path: item.url,
            // component: () => import('@/views/' + item.route),
            component: () => getComponent(item.route),
            name: item.name,
            hidden: item.isBtnRoute,
            meta: { title: item.title, icon: item.icon, roles: roles }
          }
          routers.push(router)
        }
      }
      // console.log(routers)
      const result = translateDataToTree(routers)
      resolve(result)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
