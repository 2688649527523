import request from '@/utils/request'

//  行业分类列表
export function industryList(params) {
  return request({
    url: '/api/industryClassify',
    method: 'get',
    params: params
  })
}

//  行业第二季分类
export function secIndustryList() {
  return request({
    url: '/api/industryClassify/listSec',
    method: 'get'
  })
}
