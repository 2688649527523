const getters = {
  //  permission
  permission_routes: state => state.permission.routes,
  //  user
  userInfo: state => state.user.userInfo,
  roles: state => state.user.roles,
  noticeNum: state => state.user.noticeNum,

  ///////
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,

  //  语言
  locale: state => state.app.locale,
  // user
  token: state => state.user.token,

  sysUser: state => state.user.sysUser,
  permissionList: state => state.user.permissionList,

  showPiao: state => state.constant.showPiao,

  code: state => state.pack.code,
  ossSTS: state => state.oss.ossSTS,
  /**
   * lyy
   */
  countryList: state => state.constant.countryList, // 17个国家
  allIndustryList: state => state.constant.allIndustryList, // 行业分类
  processOption: state => state.constant.processOption, // 审核状态
  languageList: state => state.constant.languageList, // 发布语言
  readOptions: state => state.constant.readOptions // 消息中心已读和未读状态

}
export default getters
