<template>
  <div style="box-sizing: content-box;">
    <navbar />
    <app-main />
    <app-footer />
    <!-- 中文酒店飘窗 -->
    <div v-if="locale === 'cn' && false" class="window-box" @click="handleGoHotel">
      <ul>
        <li class="title">酒店预订</li>
        <li>2024中国—中东欧国家产学研合作对接会</li>
        <li>会议地点：南京金陵饭店</li>
        <li>服务电话：+86-25-85485886</li>
        <li>服务邮箱：ccttc@jittc.org</li>
      </ul>
      <img src="../assets/images/hotel.png">
    </div>
    <!-- 英文酒店飘窗 -->
    <div v-else-if="false" class="window-box" style="width: 260px; line-height: 1.4;" @click="handleGoHotel">
      <ul>
        <li class="title">Hotel Reservation</li>
        <li>2024 China-CEEC Conference on Industry-University-Research Cooperation and Exchange</li>
        <li>Venue: Nanjing Jinling Hotel</li>
        <li>Phone: +86-25-85485886</li>
        <li>Email: ccttc@jittc.org</li>
      </ul>
      <img src="../assets/images/hotel.png">
    </div>
  </div>
</template>

<script>
//
import { AppMain, Navbar } from './components'
import AppFooter from '@/components/Footer/index'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    AppFooter
  },
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE
    }
  },
  // created() {
  //   this.getUserInfo()
  // },
  methods: {
    // getUserInfo() {
    //   this.$store.dispatch('user/getUserInfo').then(res => {})
    // }
    /** 酒店跳转 */
    handleGoHotel() {
      window.open(process.env.VUE_APP_HOTLE_WEB_URL, '_block')
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-wrapper {
    width: 100%;
    height: 100%;
  }
  .window-box {
    position: fixed;
    top: 22vh;
    width: 240px;
    cursor: pointer;
    box-sizing: border-box;
    ul {
      padding: 16px 16px 12px 12px;
      background-color: #2890eb;
      font-size: 14px;
      color: #ffffff;
      .title {
        padding-bottom: 2px;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
      }
    }
    img {
      width: 100%;
    }
  }
</style>
