<template>
  <section class="app-main">
    <router-view :key="key" />
  </section>
</template>

<script>
//
import { getToken  } from '@/utils/auth'
export default {
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    },
    key() {
      if (!this.userInfo && getToken()) {
        this.getUserInfo()
      }
      if (this.userInfo && !getToken()) {
        this.$store.dispatch('user/logout').then(res => {})
      }
      return this.$route.fullPath
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      // this.$store.dispatch('user/getUserInfo').then(res => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  margin: 0 auto;
  width: 1200px;
  min-height: calc(100vh - 169px);
  /*overflow: hidden;*/
}
</style>
