//  国家
import img_1 from '@/assets/images/country_icon/1.png'
import img_2 from '@/assets/images/country_icon/2.png'
import img_3 from '@/assets/images/country_icon/3.png'
import img_4 from '@/assets/images/country_icon/4.png'
import img_5 from '@/assets/images/country_icon/5.png'
import img_6 from '@/assets/images/country_icon/6.png'
import img_7 from '@/assets/images/country_icon/7.png'
import img_8 from '@/assets/images/country_icon/8.png'
import img_9 from '@/assets/images/country_icon/9.png'
import img_10 from '@/assets/images/country_icon/10.png'
import img_11 from '@/assets/images/country_icon/11.png'
import img_12 from '@/assets/images/country_icon/12.png'
import img_13 from '@/assets/images/country_icon/13.png'
import img_14 from '@/assets/images/country_icon/14.png'
import img_15 from '@/assets/images/country_icon/15.png'
import img_16 from '@/assets/images/country_icon/16.png'
import img_17 from '@/assets/images/country_icon/17.png'

//  合作机构
// import p_01 from '@/assets/images/partner_icon/1.png'
import p_02 from '@/assets/images/partner_icon/2.png'
import p_03 from '@/assets/images/partner_icon/3.png'
import p_04 from '@/assets/images/partner_icon/4.png'
import p_05 from '@/assets/images/partner_icon/5.png'
import p_06 from '@/assets/images/partner_icon/6.png'
import p_07 from '@/assets/images/partner_icon/7.png'
import p_08 from '@/assets/images/partner_icon/8.png'
import p_09 from '@/assets/images/partner_icon/9.png'
import p_10 from '@/assets/images/partner_icon/10.png'
import p_11 from '@/assets/images/partner_icon/11.png'
import p_12 from '@/assets/images/partner_icon/12.png'
import p_13 from '@/assets/images/partner_icon/13.png'
import p_14 from '@/assets/images/partner_icon/14.png'
// import p_15 from '@/assets/images/partner_icon/15.png'
import p_16 from '@/assets/images/partner_icon/16.png'
import p_17 from '@/assets/images/partner_icon/17.png'
import p_18 from '@/assets/images/partner_icon/18.png'
import p_19 from '@/assets/images/partner_icon/19.png'
import p_20 from '@/assets/images/partner_icon/20_1.jpg'
import p_21 from '@/assets/images/partner_icon/21.png'

export default {
  install(Vue) {
    Vue.prototype.$constant = {
    // {
    //   id: 1,
    //     img: p_01,
    //   name: '江苏师范大学',
    //   english: 'Jiangsu Normal University'
    // },
    // {
    //   id: 15,
    //     img: p_15,
    //   name: '考纳斯理工大学',
    //   english: 'Kaunas University of Technology'
    // }
      //  合作机构
      partnerOptions: [
        {
          id: 18,
          img: p_18,
          name: '河海大学',
          english: 'Hohai University'
        },
        {
          id: 19,
          img: p_19,
          name: '南京理工大学',
          english: 'Nanjing University of Science&Technology'
          // english: 'Nanjing University of Science and Technology'
        },
        {
          id: 2,
          img: p_02,
          name: '中国矿业大学',
          english: 'China University of Mining and Technology'
        },
        {
          id: 3,
          img: p_03,
          name: '常熟理工学院',
          english: 'Changshu Institute of Technology'
        },
        {
          id: 21,
          img: p_21,
          name: '江苏大学',
          english: 'Jiangsu University'
        },
        {
          id: 7,
          img: p_07,
          name: '新能源电动车技术与装备中东欧国家国际联合研究中心',
          english: 'International Joint Research Center of Central and Eastern European Countries on New Energy Electric Vehicle Technology and Equipments'
        },
        {
          id: 8,
          img: p_08,
          name: '保加利亚圣·伊凡·里尔斯基矿业地质大学',
          english: 'University of Mining and Geology "St. Ivan Rilski",  Bulgaria'
        },
        {
          id: 13,
          img: p_13,
          name: '保加利亚科学院',
          english: 'Bulgarian Academy of Sciences'
        },
        {
          id: 14,
          img: p_14,
          name: '布尔诺理工大学',
          english: 'Brno University of Technology'
        },
        {
          id: 6,
          img: p_06,
          name: '希腊塞萨洛尼基亚里斯多德大学',
          english: 'Aristotle University of Thessaloniki, Greece'
        },
        {
          id: 20,
          img: p_20,
          name: '布达佩斯技术与经济大学',
          english: 'Budapest University of Technology and Economics'
        },
        {
          id: 5,
          img: p_05,
          name: '波兰华沙大学',
          english: 'Universiy of Warsaw, Poland'
        },
        {
          id: 16,
          img: p_16,
          name: '波兰科学院',
          english: 'Polish Academy of Sciences'
        },
        {
          id: 9,
          img: p_09,
          name: '波兰什切青西波美拉尼亚技术大学',
          english: 'West Pomeranian University of Technology Szczecin, Poland'
        },
        {
          id: 4,
          img: p_04,
          name: '罗马尼亚布加勒斯特农业与兽医药大学',
          english: 'University of Agronomic Sciences and Veterinary Medicine of Bucharest, Romania'
        },
        {
          id: 11,
          img: p_11,
          name: '塞尔维亚诺维萨德大学',
          english: 'University of Novi Sad, Serbia'
        },
        {
          id: 17,
          img: p_17,
          name: '贝尔格莱德大学',
          english: 'University of Belgrade'
        },
        {
          id: 10,
          img: p_10,
          name: '斯洛伐克日利纳大学',
          english: 'University of Žilina, Slovak'
        },
        {
          id: 12,
          img: p_12,
          name: '斯洛文尼亚马里博尔大学',
          english: 'University of Maribor, Slovenia'
        }
        // {
        //   id: 1000,
        //   img: '',
        //   name: '',
        //   english: ''
        // }
      ],
      //  国家
      countryOptions: [{
        id: 1,
        img: img_1,
        name: '阿尔巴尼亚',
        english: 'Albania',
        code: 'ALB'
      }, {
        id: 2,
        img: img_2,
        name: '波黑',
        english: 'Bosnia and Herzegovina',
        code: 'BIH'
      }, {
        id: 3,
        img: img_3,
        name: '保加利亚',
        english: 'Bulgaria',
        code: 'BGR'
      }, {
        id: 4,
        img: img_4,
        name: '克罗地亚',
        english: 'Croatia',
        code: 'HRV'
      }, {
        id: 5,
        img: img_5,
        name: '捷克',
        english: 'Czech',
        code: 'CZE'
      },/* {
        id: 6,
        img: img_6,
        name: '爱沙尼亚',
        english: 'Estonia',
        code: 'EST'
      },*/ {
        id: 7,
        img: img_7,
        name: '希腊',
        english: 'Greece',
        code: 'GRC'
      }, {
        id: 8,
        img: img_8,
        name: '匈牙利',
        english: 'Hungary',
        code: 'HUN'
      },/* {
        id: 9,
        img: img_9,
        name: '拉脱维亚',
        english: 'Latvia',
        code: 'LVA'
      },*/ {
        id: 10,
        img: img_10,
        name: '黑山',
        english: 'Montenegro',
        code: 'MNE'
      }, {
        id: 11,
        img: img_11,
        name: '北马其顿',
        english: 'North Macedonia',
        code: 'MKD'
      }, {
        id: 12,
        img: img_12,
        name: '波兰',
        english: 'Poland',
        code: 'POL'
      }, {
        id: 13,
        img: img_13,
        name: '罗马尼亚',
        english: 'Romania',
        code: 'ROU'
      }, {
        id: 14,
        img: img_14,
        name: '塞尔维亚',
        english: 'Serbia',
        code: 'SRB'
      }, {
        id: 15,
        img: img_15,
        name: '斯洛伐克',
        english: 'Slovakia',
        code: 'SVK'
      }, {
        id: 16,
        img: img_16,
        name: '斯洛文尼亚',
        english: 'Slovenia',
        code: 'SVN'
      }, {
        id: 17,
        img: img_17,
        name: '中国',
        english: 'China',
        code: 'CHN'
      }, {
        id: 18,
        img: '',
        name: '',
        english: ''
      }, {
        id: 19,
        img: '',
        name: '',
        english: ''
      }, {
        id: 20,
        img: '',
        name: '',
        english: ''
      }],
      // 通知公告
      noticeList: [
        {
          id: 3,
          time: '2023-08-30',
          title: '意向参与对接企业汇总表'
        },
        {
          id: 2,
          time: '2023-08-28',
          title: '关于协助做好第二届中国—中东欧国家技术合作交流大会相关筹备工作的通知',
          titleEn: '',
          body: '',
          bodyEn: ''
        },
        {
          id: 1,
          time: '2023-07-25',
          title: '第二届中国—中东欧国家技术合作交流大会邀请信及初步日程',
          titleEn: 'The 2nd China-CEEC Conference on Technology Cooperation and Exchange Invitation and Program',
          body: '',
          bodyEn: ''
        }
      ],
      noticeList2: [
        // {
        //   id: 5,
        //   time: '2023-08-30',
        //   title: '中国—中东欧国家技术转移中心简介'
        // },
        {
          id: 4,
          time: '2023-08-30',
          title: '大会初步日程'
        },
        {
          id: 3,
          time: '2023-08-30',
          title: '大会活动内容简介'
        },
        {
          id: 2,
          time: '2023-08-30',
          title: '意向参与对接企业汇总表'
        },
        {
          id: 1,
          time: '2023-07-25',
          title: '第二届中国—中东欧国家技术合作交流大会邀请信及初步日程',
          titleEn: 'The 2nd China-CEEC Conference on Technology Cooperation and Exchange Invitation and Program',
          body: '',
          bodyEn: ''
        }
      ]
    }
  }
}
