<template>
  <div v-if="!item.hidden">
    <!-- 最末级菜单（没有下级子菜单） -->
    <div
      v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)"
      :class="{'is-active': isAction}"
      class="menu-wrapper"
    >
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <item
          :icon="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)"
          :title="$t(onlyOneChild.meta.title)"
          :active-menu="resolvePath(onlyOneChild.path)"
        />
      </app-link>
      <item
        v-if="showActiveRoute"
        :level="2"
        :icon="activeRoute.meta.icon"
        :title="$t(activeRoute.meta.title)"
        :active-menu="resolvePath(activeRoute.path)"
        class="is-active second_item"
      />
      <item
        v-if="showNormalRoute"
        :level="2"
        :icon="normalRoute.meta.icon"
        :title="$t(normalRoute.meta.title)"
        :active-menu="resolvePath(normalRoute.path)"
        class="is-active second_item"
      />
    </div>
  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import Item from './Item'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    },
    defaultActive: {
      type: String,
      default: ''
    }
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null
    return {}
  },
  computed: {
    isAction() {
      if (this.onlyOneChild) {
        return this.resolvePath(this.onlyOneChild.path) === this.defaultActive
      }
      return true
    },
    // 大会活动对接
    activeRoute() {
      const route = this.$route
      if (route.path === '/admin/home/reservation') {
        return route
      }
      return null
    },
    showActiveRoute() {
      if (this.activeRoute && this.onlyOneChild) {
        return this.resolvePath(this.onlyOneChild.path) === '/admin/home/index'
      }
      return false
    },
    // 常态活动对接/admin/normal
    normalRoute() {
      const route = this.$route
      if (route.path === '/admin/home/normal') {
        return route
      }
      return null
    },
    showNormalRoute() {
      if (this.normalRoute && this.onlyOneChild) {
        return this.resolvePath(this.onlyOneChild.path) === '/admin/home/index'
      }
      return false
    },
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })
      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }
      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ... parent, path: '', noShowingChildren: true }
        return true
      }
      return false
    },
    hasShowingChild(item) {
      if (item.children) {
        for (const children of item.children) {
          console.log(children)
        }
      } else {
        return false
      }
      return true
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>

<style lang="scss" scoped>
  .menu-wrapper {
    margin: 0;
    padding: 0;
    color: #333333;
    overflow: hidden;
  }
  a {
    margin: 0;
    padding: 0;
  }
  .is-active {
    color: #ffffff;
    background: linear-gradient(106deg, #409EFF 0%, #004DD6 99%) !important;
  }
  .second_item {
    height: 42px;
  }
</style>
