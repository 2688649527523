import Vue from 'vue'
import locale from 'element-ui/lib/locale'
import VueI18n from 'vue-i18n'
import messages from './langs'
// import { getLocale } from '@/utils/auth'
Vue.use(VueI18n)
//  默认中文
const i18n = new VueI18n({
  // locale: getLocale() || 'cn',
  // locale: 'en',
  locale: process.env.VUE_APP_LOCALE,
  messages
})
//  兼容element插件的多语言切换
locale.i18n((key, value) => i18n.t(key, value))

export default i18n
