<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <div>
        <router-view v-if="!$route.meta.keepAlive" :key="key" />
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" :key="key" />
        </keep-alive>
      </div>
    </transition>
  </section>
</template>
<script>
//

export default {
  name: 'AppMain2',
  computed: {
    key() {
      return this.$route.fullPath
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-main {
    position: relative;
    top: 90px;
    left: 210px;
    /*border-right: 10px solid #E8E9EB;*/
    /*box-sizing: content-box;*/
    max-width: 1180px;
    min-height: 100%;
    width: calc(100% - 210px);
    overflow-x: auto;
    box-shadow: 5px 0px 10px 0px rgba(7, 69, 134, 0.1);
  }
</style>

<style lang="scss">
  // fix css style bug in open el-dialog
  .el-popup-parent--hidden {
    .fixed-header {
      padding-right: 15px;
    }
  }
</style>
