import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const cn = {
  m: {
    title: '中国—中东欧国家技术转移中心',
    user: {
      login_text: '登录',
      register_text: '注册',
      center_text: '个人中心'
    },
    navbar: {
      title: '您好，欢迎来到中国—中东欧国家技术转移中心！',
      web_home: '网站首页',
      language_cn: '简体中文',
      language_en: 'English',
      home: '首页',
      about_us: '关于我们',
      center_dynamic: ' 新闻动态',
      notice_title: '大会资讯',
      cooperate_info: '合作信息',
      ceec_cooperate_text: '中东欧合作需求',
      china_cooperate_text: '中方合作需求',
      project_cooperate_text: '项目合作案例',
      ceec_tech_supply_text: '中东欧技术供给',
      china_tech_supply_text: '中方技术供给',
      innovation_institution_text: '创新机构介绍',
      technical_record: '科技资讯',
      link_us: '联系我们',
      logout_text: '退出登录'
    },
    //  关于我们
    aboutus: {
      frame_text: '成立背景',
      virtual_center: '服务内容',
      manifesto_text: '宗旨',
      accessory_text: '附件',
      can_download: '可下载',
      service_text: '服务内容：'
    },
    //  合作信息
    hezuo: {
      keyword_text: '关键词检索：',
      demand_name: '需求名称：',
      organization_text: '发布单位：',
      industry_title: '行业',
      country_title: '国家',
      all_text: '全部',
      country_text: '国家：',
      release_time: '发布时间：',
      industry_text: '行业分类：',
      contact_text: '联系人：',
      tel_text: '联系电话：'
    },
    //  科技资讯
    technical: {
      file_size: '文件大小',
      update_time: '上传时间',
      data_download: '资料下载'
    },
    //  联系我们
    linkus: {
      message_center: '江苏省跨国技术转移中心',
      city_text: '斯洛伐克共和国首都布拉迪斯拉发市',
      street_text: '拉马奇斯卡大街8/A号',
      postcode_text: '邮码',
      tel_text: '电话',
      email_text: '邮箱',
      address_text: '地址',
      tax_number: '增值税识别号',
      register_number: '在斯洛伐克国库注册的账号'
    },
    cooperate_country: '合作国家',
    more_text: '更多',


    // 新的
    new: {
      account_ph: '请输入单位邮箱账号',
      account_info: '填写账号信息',
      unit_info: '填写单位信息',
      mailbox: '邮箱:',
      password_v: '至少8字符,至少1数字字符,至少1小写字母,至少1大写字母,至少1特殊字符',
      businessname_ph: '请输入单位名称',
      china_ph: '请中方参会代表填写',
      company_js: '公司介绍:',
      company_ph: '请介绍下你的公司',
      introduce_ph: '重点介绍业务范围、技术特长和优势领域。内容限2000英文字符。',
      businessaddress_ph: '请输入单位地址',
      phone_text: '联系人电话:',
      phone_ph: '请输入联系人电话',
      back_ph: '上一步',
      name_ph: '名字',
      surname_ph: '姓氏',
      province_ph: '请选择所在的省市',
      demand_text: '技术需求名称',
      demand_ph: '请填写技术需求名称',
      contacts_text: '联系人',
      contacts_ph: '请填写联系人',
      contacts_phone: '联系人电话',
      contacts_phone_ph: '请填写联系人电话',
      language_text: '发布语言',
      language_ph: '请选择发布语言',
      desc_text: '技术需求简介',
      desc_ph: '请填写技术需求简介',
      nation_ph: '请选择国家',
      sub_ph: '提交审核',
      demand_tab: '合作需求列表',
      demand_con: '所有已提交审核的技术需求，通过发布技术需求告知单位的述求。',
      key_word: '关键字:',
      key_word_ph: '需求名称',
      search_ph: '查询',
      reset_ph: '重置',
      demand_add: '新增需求',
      ti_time: '提交时间',
      demand_edit: '编辑',
      businessintype_text: '行业分类',
      demand_add_text: '新增技术需求',
      demand_edit_text: '编辑技术需求',
      back: '返回',
      tinymce_ph: '请输入内容...',
      supply_tab: '技术供给列表',
      supply_con: '所有已提交审核的技术供给，通过发布技术供给告知单位所能够提供的能力。',
      supply_key: '供给名称',
      supply_text: '技术供给名称',
      shenh_ph: '审核状态:',
      supply_ph: '请填写技术供给名称',
      supply_add: '新增供给',
      supply_add_page: '新增技术供给',
      supply_edit_page: '编辑技术供给',
      view_ph: '查看',
      supply_name: '供给名称',
      sub_pass: '提交',
      code_ph: '请填写你收到的验证码',
      new_pwd: '新的密码',
      unit_info_text: '单位信息',
      edit_text: '修改',
      submit_info_text: '提交信息',
      demand_other_ph: '预期达到目的、技术指标',
      technology_supply: '技术简介',
      innovation_point: '创新点',
      supply_other_ph: '技术优势、技术应用、商业计划',
      company_name_text: '单位名称',
      message_ph: '消息通知',
      message_text: '消息详情',
      title_text: '标题',
      status_text: '状态',
      time_text: '时间',
      operate_text: '操作成功',
      technical_requirements_details: '技术需求详情',
      technical_supply_details: '技术供给详情',
      unit_type_text: '单位类型',
      chat_message_text: '聊天消息',
      please_inter_text_ph: '请输入内容',
      docking_appointment_text: '对接预约',
      send_message_text: '发送消息',
      read_text: '已读',
      unread_text: '未读',
      apply_unit_info_text: '报名单位信息',
      unit_demand_text: '单位需求',
      purpose_of_attendance: '参会目的：',
      apply_intro_text: '请填写你的合作需求（或）需要解决的技术难题',
      apply_intro_remark_text: '请用英文详细填写您的合作需求与（或）需要解决的技术难题。',
      apply_sd_remark_text: '同意他(她)人通过发布的合作需求进行对接。',
      docking_date_text: '对接日期：',
      apply_unit_text: '对接预约',
      my_schedule_text: '我的预约',
      apply_info_text: '报名信息',
      apply_success_text: '报名信息提交成功请耐心等待审核！',
      home_open_type_text: '开放式对接',
      collaboration_is_easier: '“让合作更简单”',
      go_view_demand_text: '合作需求',
      go_view_supply_text: '前往查看供给',
      my_docking_text: '我的对接',
      matching_item_text: '匹配项目',
      //--------------------------
      cooperation_requirement_name: '合作需求名称(中)',
      cooperation_requirement_name_en: '合作需求名称(英)',
      cooperation_requirement_name_ph: '请填写合作需求名称',
      contact_name: '联系人(中)',
      contact_name_en: '联系人(英)',
      contact_name_ph: '请填写联系人',
      telephone_text: '联系人电话',
      cooperation_requirements_description: '合作需求简介(中)',
      cooperation_requirements_description_en: '合作需求简介(英)',
      ideal_way_of_cooperation: '合作方式(中)',
      ideal_way_of_cooperation_en: '合作方式(英)',
      menus: {
        personal_center_text: '个人中心',
        convention_activity_text: '大会活动',
        normal_docking_text: '常态对接',
        profile_text: '个人信息',
        cooperate_text: '合作需求',
        cooperation_demand_text: '技术需求',
        cooperation_supply_text: '技术供给',
        message_center_text: '消息中心'
      }
    },
    login_page_name: '您好，欢迎来到江苏省跨国技术转移中心！',
    name: '客户中心',
    check_failure: '校验失败，请检查信息',
    validate_normal_text: '此项为必填项',
    new_text: '新建',
    new_success: '新建成功',
    delete_text: '删除',
    save_text: '保存',
    save_success: '保存成功',
    handle_text: '操作',
    prompt_text: '提示',
    sure_text: '确定',
    cancel_text: '取消',
    continue_text: '继续',
    delete_success: '删除成功',
    submit_success_text: '提交成功',
    search_filters: '筛选搜索',
    search_text: '搜索',
    reset_text: '重置',
    logout_text: '退出',
    password_changed_successfully_text: '密码修改成功',
    prompt_before_text: '此操作将删除「',
    prompt_after_text: '」,是否继续？',
    prompt_sure_delete_text: '确认删除该消息？',

    //  状态
    waiting_review: '待审核',
    already_passed: '已通过',
    not_pass: '未通过',
    normal_text: '正常',
    lose_efficacy: '失效',
    have_deleted: '已删除',
    have_checked: '已审核',

    //  顶部
    top_title: '您好，欢迎来到江苏省跨国技术转移中心！',
    login_text: '登录',
    register_text: '注册',
    website_homepage: '网站首页',
    back_homepage: '返回首页',

    //  登录页面
    back_home: '返回首页',
    user_login: '用户登录',
    account_text: '账号:',
    account_ph: '请输入账号',
    password_text: '密码:',
    password_ph: '请输入密码',
    forget_text: '忘记密码？',
    remember_password: '记住密码',
    register_immediately: '立即注册',
    remember_remark: '记录密码功能不宜在公共场所(如网吧等)使用,以防密码泄露.您确定要使用此功能吗?',

    //  注册
    user_regist: '用户注册',
    businessemail_text: '单位邮箱:',
    businessemail_ph: '请输入单位邮箱',
    businessemail_remark: '此邮箱将会接受验证码',
    confirm_password: '确认密码:',
    confirm_password_ph: '请输入确认密码',
    businessnamechina_text: '单位名称(中):',
    businessnamechina_ph: '请输入单位名称(中)',
    businessname_text: '单位名称(英):',
    businessname_ph: '请输入单位名称(英)',
    businesstype_text: '单位类型:',
    businesstype_ph: '请选择单位类型',
    businessintype_text: '行业分类:',
    first_businessintype_text: '请选择一级行业',
    second_businessintype_text: '请选择二级行业',
    businessintype_more: '最多选择五个分类',
    country_text: '国家:',
    select_place: '请选择',
    businessno_text: '统一社会信息代码:',
    businessno_ph: '请输入统一社会信息代码',
    businesscardphotourl_text: '营业执照照片/组织机构代码照片:',
    businesscardphotourl_remark0: '(支持3M以内的JPG、PNG图片)',
    businesscardphotourl_remark1: '证件照必须是清晰彩色原件电子版，可以是扫描件或者数码拍摄照片，仅支持jpg、jpeg、png的图片格式。',
    businessaddress_text: '单位地址(中):',
    businessaddress_ph: '请输入详细地址(中)',
    businessaddressEnglish_text: '单位地址(英):',
    businessaddressEnglish_ph: '请输入详细地址(英)',
    postcode_text: '邮编:',
    postcode_ph: '请输入邮编',
    cname_text: '单位联系人(中):',
    cname_ph: '请输入单位联系人(中)',
    cnameEnglish_text: '单位联系人(英):',
    cnameEnglish_ph: '请输入单位联系人(英)',
    linkemail_text: '联系人邮箱:',
    linkemail_ph: '请输入联系人邮箱',
    telephone_text: '手机号码:',
    telephone_ph: '请输入手机号码',
    code_text: '验证码:',
    input_code_please: '请输入验证码',
    input_please: '请输入',
    referees_text: '推荐人:',
    read_and_accept: '我已阅读并同意',
    protocol_name: '《江苏省跨国技术转移中心协议》',
    submit_regist: '提交注册',
    sure_submit: '确认提交',
    send_code_text: '发送验证码',
    regist_success: '注册成功！',
    email_format_fail: '邮箱格式不正确！',
    email_format_used: '该邮箱已被注册！',
    org_name_existing: '该组织名称已被使用',
    resend_text: '重新发送',
    double_select_remark: '不能重复选择',
    purpose_of_attendance: '参会目的:',
    purpose_of_attendance_ph: '请用英文详细填写您的合作需求与（或）需要解决的技术难题。',

    //  找回密码
    retrieve_password: '找回密码',
    retrieve_Email: '账号:',
    user_businessemail_ph: '单位客户邮箱',
    email_verification: '验证码',
    next_step: '下一步',
    new_login_pw: '新登录密码',
    new_login_pw_ph: '请输入新登录密码',
    new_confirm_pw: '确认新密码:',
    new_confirm_pw_ph: '请再次输入新登录密码',
    step_remark_3_0: '新密码已重置成功，将在',
    step_remark_3_1: '秒后跳转至登录页面',

    //  修改密码
    edit_pw: '修改密码',
    old_pwd: '旧密码',
    old_pwd_ph: '请输入旧密码',
    new_pwd: '新密码',
    new_pwd_ph: '请输入新密码',
    new_pwd_remark: '新密码为6-20位',
    confirm_pwd: '重复新密码',
    confirm_pwd_ph: '请再次输入新密码',
    confirm_pwd_remark: '确认新密码与新密码不一致',

    //  首页
    perfect_information: '完善资料',
    country_area: '国家/地区:',
    i_publis_tec: '我发布的技术',
    preview_all_tec: '查看全部技术',
    i_publis_demand: '我发布的需求',
    preview_all_demand: '查看全部需求',

    //  单位资料
    unit_info: '单位资料',
    basic_info: '基本资料',
    other_info: '其他资料',
    unit_header: '单位头像:',
    unit_header_remark: '从您的电脑中上传图片作为单位头像',
    unit_introduction: '单位简介(中):',
    unit_introduction_english: '单位简介(英):',
    unit_url: '单位网址:',
    unit_url_ph: '请输入单位网址',
    unit_size: '单位规模:',
    unit_date: '成立时间:',
    product_service: '产品/服务(中):',
    product_service_ph: '请输入产品/服务(中)',
    product_service_english: '产品/服务(英):',
    product_service_english_ph: '请输入产品/服务(英)',

    //  联系人
    contacts_manager: '联系人管理',
    number_text: '序号',
    contacts_add: '添加联系人',
    contacts_edit: '修改联系人',
    contacts_preview: '联系人详情',
    contacts_name: '联系人姓名(中)',
    contacts_name_ph: '请输入联系人姓名(中)',
    contacts_name_en: '联系人姓名(英)',
    contacts_name_en_ph: '请输入联系人姓名(英)',
    contacts_telephone: '联系电话',
    contacts_telephone_ph: '请输入联系电话',
    contacts_email: '联系人邮箱',
    job_text: '职务(中)',
    job_text_ph: '请输入职务(中)',
    job_text_en: '职务(英)',
    job_text_en_ph: '请输入职务(英)',
    fax_text: '传真',
    fax_tex_ph: '请输入传真',
    contacts_status: '联系人状态',
    data_status: '资料状态',
    not_available: '暂无',
    delete_row: '请选择要删除的行!',
    contacts_delete_remind: '此操作将永久删除联系人, 是否继续？',

    //  技术需求
    perfect_information_remark: '您好！请先完善您的单位信息。',
    technical_demand: '技术需求',
    demand_preview: '需求预览',
    demand_edit: '需求编辑',
    demand_publish: '需求发布',
    demand_name: '需求名称',
    demand_name_ph: '请输入需求名称',
    first_classify: '一级分类',
    second_classify: '二级分类',
    publish_time: '发布时间',
    unit_contacts: '单位联系人',
    center_contacts: '中心负责人',
    check_status: '审核状态',
    usable_text: '可用',
    disabled_text: '不可用',
    demand_delete_remind: '此操作将永久删除此技术需求, 是否继续？',
    demand_delete_remind1: '此操作将永久删除选中的技术需求, 是否继续？',
    introduction_text: '介绍',
    illustration_text: '插图',
    introduction_input_ph: '温馨提示：(请输入中文..)上传普通文本时，中文请使用微软雅黑、14号字；英文请使用Verdana、14号字。',

    //  技术供给
    technical_supply: '技术供给',
    technical_publish: '技术发布',
    technical_preview: '技术预览',
    nation_text: '国家',
    technical_edit: '技术编辑',
    technical_name: '技术名称',
    technical_name_ph: '请输入技术名称',
    technical_type: '技术分类',
    supply_delete_remind: '此操作将永久删除此技术供给, 是否继续？',
    supply_delete_remind1: '此操作将永久删除选中的技术供给, 是否继续？',
    enter_meeting_text: '进入会议',
    action_text: '行为',
    dock_info_text: '对接信息',
    send_text: '发送',
    table_number: '桌号'
  },
  ...zhLocale
}
export default cn
