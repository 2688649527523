<template>
  <div>
    <div class="navbar">
      <div class="navbar-wid">
        <div class="hello">
          {{ $t('m.navbar.title') }}
          <div v-if="!haveToken">
            <!--target="_blank"-->
            <!-- 登录 -->
            <span
              @click="handleGoPersonal"
            >{{ $t('m.user.login_text') }}</span>
            <!-- 注册 -->
            <span
              @click="handleGoPersonalRegister"
            >{{ $t('m.user.register_text') }}</span>
          </div>
          <!-- 个人中心 -->
          <span
            v-else
            @click="handleGoPersonal"
          >[{{ $t('m.user.center_text') }}]
          </span>
        </div>
        <div class="nav_widr">
          <div>
            <img src="../../assets/images/nav/regi_03.png">
          </div>
          <div>
            <!-- 网站首页 -->
            <a href="/" style="text-decoration: none;">{{ $t('m.navbar.web_home') }}</a>
          </div>
          <div class="cent_line" />
          <div>
            <img v-if="this.$language === 'cn'" src="../../assets/images/nav/regi_07.png">
            <img v-else src="../../assets/images/nav/regi_08.png">
          </div>
          <div>
            <a v-if="this.$language === 'cn'" @click="handleSwitchLocale">English</a>
            <a v-else @click="handleSwitchLocale">简体中文</a>
            <!--<a-->
            <!--v-if="this.$language === 'cn'"-->
            <!--href="https://en.c-ceec.org.cn"-->
            <!--target="_blank"-->
            <!--&gt;English</a>-->
            <!--<a-->
            <!--v-else-->
            <!--href="https://c-ceec.org.cn"-->
            <!--target="_blank"-->
            <!--&gt;简体中文</a>-->
          </div>
        </div>
      </div>
    </div>
    <div class="pic1">
      <img src="../../assets/images/nav/logo-title-3.png">
      <div v-if="false" class="right-hot" @click="$router.push({ path: '/introduction' })">
        <img style="width: auto; height: 22px; margin-right: 15px" src="../../assets/images/nav/inform_icon.png">
        <div>活动大会简介章程</div>
        <div class="hot-icon">HOT</div>
      </div>
    </div>
    <div class="indexNav">
      <ul class="my_navli">
        <!-- 首页 -->
        <li>
          <router-link to="/">
            <a :class="{ colorIndex : activeIndex === 0 }" style="cursor: pointer;">
              {{ $t('m.navbar.home') }}
            </a>
          </router-link>
        </li>
        <div class="line" />
        <!-- 关于我们 -->
        <li>
          <router-link to="/aboutus">
            <a :class="{ colorIndex : activeIndex === 1 }" style="cursor: pointer;">
              {{ $t('m.navbar.about_us') }}
            </a>
          </router-link>
        </li>
        <div class="line" />
        <!--  新闻动态 -->
        <li>
          <div
            :style="{ overflow: mouseIndex === 2 ? 'visible' : 'hidden'}"
            class="hover_drop2"
            @mousemove="handleMousemove(2)"
            @mouseout="handleMouseout"
          >
            <a
              :class="{ colorIndex : activeIndex === 2 }"
            >{{ this.$language === 'cn' ? ' 新闻中心' : 'News Center' }}</a>
            <ul class="drop_li2">
              <!-- 新闻动态 -->
              <li>
                <router-link to="/dynamic">
                  <a
                    :class="{colorIndex: routeName === 'Dynamic'}"
                  >{{ this.$language === 'cn' ? ' 新闻动态' : 'News' }}</a>
                </router-link>
              </li>
              <!-- 通知公告 -->
              <li>
                <router-link to="/dynamic/notice">
                  <a
                    :class="{colorIndex: routeName === 'Notice'}"
                  >{{ this.$language === 'cn' ? '大会资讯' : 'Conference Information' }}</a>
                </router-link>
              </li>
            </ul>
          </div>
          <!--<router-link to="/dynamic">-->
          <!--<a :class="{ colorIndex : activeIndex === 2 }" style="cursor: pointer;">-->
          <!--{{ $t('m.navbar.center_dynamic') }}-->
          <!--</a>-->
          <!--</router-link>-->
        </li>
        <div class="line" />
        <!--<li>-->
        <!--<a :class="{ colorIndex : activeIndex === 2 }" style="cursor: pointer;">专家库</a>-->
        <!--</li>-->
        <!-- 合作信息 -->
        <!--:style="{width: this.$language === 'cn' ? '169px' : '180px'}"-->
        <li>
          <!--width: this.$language === 'cn' ? '169px' : '180px'-->
          <div
            :style="{ overflow: mouseIndex === 3 ? 'visible' : 'hidden'}"
            class="hover_drop2"
            @mousemove="handleMousemove(3)"
            @mouseout="handleMouseout"
          >
            <!--:style="{width: this.$language === 'cn' ? '169px' : '180px'}"-->
            <a
              :class="{ colorIndex : activeIndex === 3 }"
            >{{ $t('m.navbar.cooperate_info') }}</a>
            <ul class="drop_li2">
              <!-- 中东欧合作需求 -->
              <li>
                <router-link to="/cooperate">
                  <!--style="width: 169px;"-->
                  <a
                    v-if="this.$language === 'cn'"
                    :class="{colorIndex: routeName === 'CooperateCEEC'}"
                  >{{ $t('m.navbar.ceec_cooperate_text') }}</a>
                  <a
                    v-else
                    :class="{colorIndex: routeName === 'CooperateCEEC'}"
                    class="child-menu-en"
                  >{{ $t('m.navbar.ceec_cooperate_text') }}</a>
                </router-link>
              </li>
              <!-- 中方合作需求 -->
              <li>
                <router-link to="/cooperate/china">
                  <!--style="width: 169px;"-->
                  <a
                    v-if="this.$language === 'cn'"
                    :class="{colorIndex: routeName === 'CooperateChina'}"
                  >{{ $t('m.navbar.china_cooperate_text') }}</a>
                  <a
                    v-else
                    :class="{colorIndex: routeName === 'CooperateChina'}"
                    class="child-menu-en"
                  >{{ $t('m.navbar.china_cooperate_text') }}</a>
                </router-link>
              </li>
              <!-- 中东欧技术供给 -->
              <li v-if="false">
                <router-link to="/cooperate/tech/supply/ceec">
                  <!--style="width: 169px;"-->
                  <a
                    v-if="this.$language === 'cn'"
                    :class="{colorIndex: routeName === 'TechSupplyCEEC'}"
                  >{{ $t('m.navbar.ceec_tech_supply_text') }}</a>
                  <a
                    v-else
                    :class="{colorIndex: routeName === 'TechSupplyCEEC'}"
                    class="child-menu-en"
                  >{{ $t('m.navbar.ceec_tech_supply_text') }}</a>
                </router-link>
              </li>
              <!-- 中方技术供给 -->
              <li v-if="false">
                <router-link to="/cooperate/tech/supply/china">
                  <!--style="width: 169px;"-->
                  <a
                    v-if="this.$language === 'cn'"
                    :class="{colorIndex: routeName === 'TechSupplyChina'}"
                  >{{ $t('m.navbar.china_tech_supply_text') }}</a>
                  <a
                    v-else
                    :class="{colorIndex: routeName === 'TechSupplyChina'}"
                    class="child-menu-en"
                  >{{ $t('m.navbar.china_tech_supply_text') }}</a>
                </router-link>
              </li>
              <!-- 项目合作案例 -->
              <li>
                <router-link to="/cooperate/project">
                  <!--style="width: 169px;"-->
                  <a
                    v-if="this.$language === 'cn'"
                    :class="{colorIndex: routeName === 'CooperateProject'}"
                  >{{ $t('m.navbar.project_cooperate_text') }}</a>
                  <a
                    v-else
                    :class="{colorIndex: routeName === 'CooperateProject'}"
                    class="child-menu-en"
                  >{{ $t('m.navbar.project_cooperate_text') }}</a>
                </router-link>
              </li>
              <!-- 创新机构介绍 -->
              <li v-if="false">
                <router-link to="/cooperate/innovation">
                  <!--style="width: 169px;"-->
                  <a
                    v-if="this.$language === 'cn'"
                    :class="{colorIndex: routeName === 'Innovation'}"
                  >{{ $t('m.navbar.innovation_institution_text') }}</a>
                  <a
                    v-else
                    :class="{colorIndex: routeName === 'Innovation'}"
                    class="child-menu-en"
                  >{{ $t('m.navbar.innovation_institution_text') }}</a>
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <div class="line" />
        <li v-if="false">
          <!--<a href="http://113.31.124.148:30026/" target="_blank" style="cursor: pointer;">i-PIC</a>-->
          <a v-if="language === 'cn'" href="https://omp.c-ceec.org.cn" target="_blank" style="cursor: pointer;">
            云对接平台
          </a>
          <el-tooltip v-else effect="dark" content="Online Matchmaking Platform" placement="top">
            <a href="https://omp.c-ceec.org.cn" target="_blank" style="cursor: pointer;">
              OMP
            </a>
          </el-tooltip>
        </li>
        <!-- 科技资讯 -->
        <li>
          <router-link to="/technical">
            <a :class="{ colorIndex : activeIndex === 5 }" style="cursor: pointer;">
              {{ $t('m.navbar.technical_record') }}
            </a>
          </router-link>
        </li>
        <div class="line" />
        <!-- 联系我们 -->
        <li>
          <router-link to="/linkus">
            <a :class="{ colorIndex : activeIndex === 6 }" style="cursor: pointer;">
              {{ $t('m.navbar.link_us') }}
            </a>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
//
import Sidebar from './Sidebar/index'
import { getToken } from '@/utils/auth'
// import { setLocale } from '@/utils/auth'

export default {
  components: {
    Sidebar
  },
  data() {
    return {
      activeIndex: 0,
      routeName: '',
      mouseIndex: null,
      language: this.$i18n.locale
    }
  },
  watch: {
    $route(nowRouter) {
      console.log(nowRouter, '===================')
      this.routeName = nowRouter.name
      this.formatActiveIndex()
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    },
    haveToken() {
      return getToken() ? true : false
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }
  },
  created() {
    document.title = this.$t('m.title')
    this.routeName = this.$route.name
    this.formatActiveIndex()
  },
  methods: {
    //  语言切换
    handleSwitchLocale() {
      if (this.$language === 'cn') {
        // 当前为中文网站跳转英文网站
        window.location.href = process.env.VUE_APP_EN_WEA_URL
      } else {
        // 当前为英文网站跳转中文网站
        window.location.href = process.env.VUE_APP_CN_WEA_URL
      }
      // window.location.href = 'http://113.31.124.148:30025/#/home'
      // window.location.reload()
      // this.$i18n.locale = lang
      // setLocale(lang)
      // window.location.reload()
    },
    handleGoPersonal() {
      if (this.$language === 'cn') {
        // 当前为中文网站
        window.location.href = process.env.VUE_APP_PERSONAL_CN_WEB_URL
      } else {
        // 当前为英文网站
        window.location.href = process.env.VUE_APP_PERSONAL_EN_WEB_URL
      }
    },
    handleGoPersonalRegister() {
      if (this.$language === 'cn') {
        // 当前为中文网站
        window.location.href = process.env.VUE_APP_PERSONAL_CN_WEB_URL + '/#/regist'
      } else {
        // 当前为英文网站
        window.location.href = process.env.VUE_APP_PERSONAL_EN_WEB_URL + '/#/regist'
      }
    },
    //
    formatActiveIndex() {
      // console.log(this.routeName)
      // if (this.routeName === 'Home') {
      //   this.activeIndex = 0
      // } else if (this.routeName === 'AboutUs') {
      //   this.activeIndex = 1
      // } else if (this.routeName === 'Dynamic' || this.routeName === 'DynamicDetails') {
      //   this.activeIndex = 2
      // } else if (this.routeName === 'CooperateCEEC' || this.routeName === 'CooperateChina' || this.routeName === 'CooperateProject' || this.routeName === 'CooperateDetails') {
      //   this.activeIndex = 2
      // } else if (this.routeName === 'Technical') {
      //   this.activeIndex = 5
      // } else if (this.routeName === 'LinkUs') {
      //   this.activeIndex = 6
      // }

      if (this.activeMenu === '/home') {
        this.activeIndex = 0
      } else if (this.activeMenu === '/aboutus') {
        this.activeIndex = 1
      } else if (this.activeMenu === '/dynamic') {
        this.activeIndex = 2
      } else if (this.activeMenu === '/cooperate') {
        this.activeIndex = 3
      } else if (this.activeMenu === '/technical') {
        this.activeIndex = 5
      } else if (this.activeMenu === '/linkus') {
        this.activeIndex = 6
      }
    },
    //  鼠标技术信息移入
    handleMousemove(val) {
      this.mouseIndex = val
      // alert('鼠标技术信息移入' + this.mouseIndex)
    },
    //  鼠标技术信息移出
    handleMouseout() {
      this.mouseIndex = null
      // alert('鼠标技术信息移出' + this.mouseIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
  .navbar {
    width: 100%;
    height: 35px;
    margin: 0 auto;
    min-width: 1200px;
    background-color: #f8f8f8;
    .navbar-wid {
      margin: 0 auto;
      width: 1200px;
      height: 35px;
      line-height: 35px;
      .hello {
        float: left;
        display: flex;
        font-size: 12px;
        span {
          padding: 0 5px;
          color: #006bbf;
          cursor: pointer;
        }
      }
      .nav_widr {
        float: right;
        div {
          display: inline-block;
          font-size: 12px;
          img {
            padding-right: 6px;
            vertical-align: -2px;
          }
        }
        a {
          padding: 0 3px;
          color: #656565;
          cursor: pointer;
        }
        .cent_line {
          width: 1px;
          height: 12px;
          background-color: #999;
          margin-left: 9px;
          margin-right: 10px;
          vertical-align: -1 px;
        }
      }
    }
  }
  .pic1 {
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: space-between;*/
    margin: 0 auto;
    padding-top: 15px;
    height: 80px;
    width: 1200px;
    /*background-color: red;*/
    img {
      border: 0;
      width: auto;
      height: 65px;
      vertical-align: middle;
    }
    .right-hot {
      float: right;
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 14px;
      color: #333333;
      cursor: pointer;
      .hot-icon {
        margin-left: 20px;
        width: 34px;
        height: 18px;
        color: #ffffff;
        font-size: 12px;
        text-align: center;
        border-radius: 2px;
        background: linear-gradient(116deg, rgba(255,98,98,0.80) 4%, rgba(255,35,35,0.81) 100%);
      }
    }
  }
  .indexNav {
    width: 1200px;
    margin: 0 auto;
    height: 39px;
    background: -webkit-linear-gradient(#3B98FC, #014AA4);
    background: -o-linear-gradient(#3B98FC, #014AA4);
    background: -moz-linear-gradient(#3B98FC, #014AA4);
    background: linear-gradient(#3B98FC, #014AA4);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3B98FC', endColorstr='#014AA4',GradientType=0 );

    /*background: -webkit-linear-gradient(#666666, #8e969c);*/
    /*background: -o-linear-gradient(#666666, #8e969c);*/
    /*background: -moz-linear-gradient(#666666, #8e969c);*/
    /*background: linear-gradient(#666666, #8e969c);*/
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#666666', endColorstr='#8e969c',GradientType=0 );*/

    .my_navli {
      display: flex;
      align-items: center;
      width: 1200px;
      margin: 0 auto;
      li {
        /*width: 168px;*/
        width: 198px;
        text-align: center;
        height: 39px;
        float: left;
        /*border-left: 1px #595e61 solid;*/
        /*border-right: 1px #babec0 solid;*/
        a {
          display: block;
          color: #fff;
          line-height: 39px;
          font-size: 14px;
          text-decoration: none;
          /*background-color: red;*/
        }
        a:hover {
          color: #ffc000;
        }
      }
      li:first-child {
        border-left: none;
      }
      li:last-child {
        border-right: none;
      }
      .line {
        width: 1px;
        height: 18px;
        background-color: #ffffff;
      }
    }
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      list-style: none;
    }
  }
  .colorIndex {
    color: #ffc000 !important;
  }
  .hover_drop2 {
    height: 39px;
    position: relative;
    text-align: center;
    .drop_li2 {
      position: absolute;
      top: 39px;
      left: 0;
      z-index: 1;
      /*background-color: #ffffff;*/
      background-color: #6e6f70;
      li {
        height: 35px;
        text-align: center;
        line-height: 35px;
        border: none;
        a {
          display: block;
          height: 35px;
          cursor: pointer;
        }
      }
    }
  }
  .child-menu-en {
    width: calc(100% - 1px);
    font-size: 12px !important;
    zoom: 0.95;
  }
</style>
