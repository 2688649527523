import request from '@/utils/request'

// 17个国家
export function getCountryList() {
  return request({
    url: '/api/cceec/country',
    method: 'get'
  })
}
// 获取需求列表
export function getDemandList(params) {
  return request({
    url: '/api/demand/list',
    method: 'get',
    params: params
  })
}

// 需求详情
export function getViewDemand(id) {
  return request({
    url: `/api/demand/${id}`,
    method: 'get'
  })
}

// 删除需求
export function deleDelDemand(id) {
  return request({
    url: `/api/demand/${id}`,
    method: 'delete'
  })
}

// 新增需求
export function addDemand(data) {
  return request({
    url: '/api/demand',
    method: 'post',
    data: data
  })
}
// 编辑需求
export function editDemand(data) {
  return request({
    url: '/api/demand',
    method: 'put',
    data: data
  })
}

// 发布/编辑需求
export function pubDemand(data) {
  return request({
    url: '/api/supAndDemand/pubDemand',
    method: 'post',
    data: data
  })
}

// 行业分类(包含一二级)
export function getFirAndSec() {
  return request({
    url: '/api/industryClassify/firAndSec',
    method: 'get'
  })
}
