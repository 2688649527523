import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import Layout from '@/layout'
import Layout2 from '@/layout2'

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    hidden: true,
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home/index'),
        meta: { title: '首页', activeMenu: '/home' }
      },
      //   新闻动态列表
      {
        path: 'dynamic',
        name: 'Dynamic',
        component: () => import('@/views/dynamic/list/index'),
        meta: { title: ' 新闻动态', activeMenu: '/dynamic' }
      },
      //   新闻动态详情
      {
        path: 'dynamicDetails',
        name: 'DynamicDetails',
        component: () => import('@/views/dynamic/details/index'),
        meta: { title: '新闻动态详情', activeMenu: '/dynamic' }
      },
      {
        path: 'aboutus',
        name: 'AboutUs',
        component: () => import('@/views/aboutus/index'),
        meta: { title: '关于我们', activeMenu: '/aboutus' }
      },
      {
        path: 'linkus',
        name: 'LinkUs',
        component: () => import('@/views/linkus/index'),
        meta: { title: '联系我们', activeMenu: '/linkus' }
      },
      {
        path: 'technical',
        name: 'Technical',
        component: () => import('@/views/technical/index'),
        meta: { title: '科技资讯', activeMenu: '/technical' }
      },
      //  国家介绍
      {
        path: 'country',
        name: 'Country',
        component: () => import('@/views/country/index'),
        meta: { title: '国家', activeMenu: '/home' }
      },
      // 大会介绍
      {
        path: 'introduction',
        name: 'Introduction',
        component: () => import('@/views/introduction/index')
      }
    ]
  },
  //   新闻动态
  {
    path: '/dynamic',
    component: Layout,
    redirect: '/dynamic/list',
    hidden: true,
    children: [
      {
        path: 'list',
        name: 'Dynamic',
        component: () => import('@/views/dynamic/list/index'),
        meta: { title: ' 新闻动态', activeMenu: '/dynamic' }
      },
      {
        path: 'details',
        name: 'DynamicDetails',
        component: () => import('@/views/dynamic/details/index'),
        meta: { title: '动态详情', activeMenu: '/dynamic' }
      },
      {
        path: 'notice',
        name: 'Notice',
        component: () => import('@/views/dynamic/notice/list'),
        meta: { title: '通知公告', activeMenu: '/dynamic' }
      },
      {
        path: 'noticeDetails',
        name: 'NoticeDetails',
        component: () => import('@/views/dynamic/notice/details'),
        meta: { title: '公告详情', activeMenu: '/dynamic' }
      }
    ]
  },
  //  合作专区
  {
    path: '/cooperate',
    component: Layout,
    redirect: '/cooperate/ceec',
    hidden: true,
    children: [
      {
        path: 'ceec',
        name: 'CooperateCEEC',
        component: () => import('@/views/cooperate/ceec/index'),
        meta: { title: '中东欧合作需求', activeMenu: '/cooperate' }
      },
      {
        path: 'china',
        name: 'CooperateChina',
        component: () => import('@/views/cooperate/china/index'),
        meta: { title: '中方合作需求', activeMenu: '/cooperate' }
      },
      {
        path: 'tech/supply/ceec',
        name: 'TechSupplyCEEC',
        component: () => import('@/views/cooperate/tech/supply/ceec'),
        meta: { title: '中东欧技术供给', activeMenu: '/cooperate' }
      },
      {
        path: 'tech/supply/china',
        name: 'TechSupplyChina',
        component: () => import('@/views/cooperate/tech/supply/china'),
        meta: { title: '中方技术供给', activeMenu: '/cooperate' }
      },
      {
        path: 'project',
        name: 'CooperateProject',
        component: () => import('@/views/cooperate/project/index'),
        meta: { title: '项目合作案例', activeMenu: '/cooperate' }
      },
      {
        path: 'innovation',
        name: 'Innovation',
        component: () => import('@/views/cooperate/innovation/index'),
        meta: { title: '创新机构介绍', activeMenu: '/cooperate' }
      },
      {
        path: 'details',
        name: 'CooperateDetails',
        component: () => import('@/views/cooperate/details/index'),
        meta: { title: '详情', activeMenu: '/cooperate' }
      }
    ]
  },
  /***************************************/
  /*
  //  登录
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  //  注册
  {
    path: '/regist',
    name: 'Regist',
    component: () => import('@/views/register/index'),
    hidden: true
  },
  //  忘记密码
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('@/views/forget/index'),
    hidden: true
  },
  // 用户中心
  {
    path: '/admin/home',
    redirect: '/admin/home/index',
    component: Layout2,
    meta: { title: '个人中心' },
    children: [
      // 个人中心
      {
        path: 'index',
        name: 'AdminHome',
        component: () => import('@/views/userCenter/home/index'),
        meta: { title: 'm.new.menus.personal_center_text', icon: 'homeIcon', hidden: false }
      },
      {
        path: 'apply',
        name: 'Apply',
        hidden: true,
        component: () => import('@/views/userCenter/home/apply/index'),
        meta: { title: '报名', icon: 'homeIcon', hidden: false, activeMenu: '/admin/home/index' }
      },
      {
        path: 'reservation',
        name: 'ReserVation',
        hidden: true,
        component: () => import('@/views/userCenter/home/reservation/index'),
        meta: { title: 'm.new.menus.convention_activity_text', hidden: true, activeMenu: '/admin/home/reservation' }
      },
      {
        path: 'normal',
        hidden: true,
        component: () => import('@/views/userCenter/home/reservation/index'),
        meta: { title: 'm.new.menus.normal_docking_text', activeMenu: '/admin/home/normal' }
      }
    ]
  },
  // 个人信息
  {
    path: '/admin/record',
    redirect: '/admin/record/index',
    component: Layout2,
    meta: { title: '个人信息' },
    children: [
      // 档案资料
      {
        path: 'index',
        name: 'AdminRecord',
        component: () => import('@/views/userCenter/record/index'),
        meta: { title: 'm.new.menus.profile_text', icon: 'recordIcon', hidden: false }
      }
    ]
  },
  // 合作需求
  // {
  //   path: '/admin/collaborate',
  //   redirect: '/admin/collaborate/index',
  //   component: Layout2,
  //   meta: { title: '合作需求' },
  //   children: [
  //     {
  //       path: 'index',
  //       name: 'Collaborate',
  //       component: () => import('@/views/userCenter/cooperate/index'),
  //       meta: {
  //         title: 'm.new.menus.cooperate_text',
  //         icon: 'demandIcon',
  //         hidden: false
  //       }
  //     }
  //   ]
  // },
  {
    path: '/admin/demand',
    redirect: '/admin/demand/index',
    component: Layout2,
    meta: { title: '合作需求' },
    children: [
      // 技术需求
      {
        path: 'index',
        name: 'SkillDemand',
        component: () => import('@/views/userCenter/demand/index'),
        meta: { title: 'm.new.menus.cooperate_text', icon: 'demandIcon', hidden: false }
      },
      {
        path: 'add',
        name: 'AddDemand',
        component: () => import('@/views/userCenter/demand/add/index'),
        hidden: true,
        meta: { title: '新增合作需求', icon: 'demandIcon', activeMenu: '/admin/demand/index' }
      },
      {
        path: 'edit',
        name: 'EditDemand',
        component: () => import('@/views/userCenter/demand/edit/index'),
        hidden: true,
        meta: { title: '编辑合作需求', icon: 'demandIcon', activeMenu: '/admin/demand/index' }
      },
      {
        path: 'view',
        name: 'ViewDemand',
        component: () => import('@/views/userCenter/demand/view/index'),
        hidden: true,
        meta: { title: '合作需求详情', icon: 'demandIcon', activeMenu: '/admin/demand/index' }
      }
    ]
  },
  {
    path: '/admin/personalCenter',
    redirect: '/admin/personalCenter/index',
    component: Layout2,
    meta: { title: '消息中心' },
    children: [
      // 消息中心
      {
        path: 'index',
        name: 'PersonalCenter',
        component: () => import('@/views/userCenter/personalCenter/index'),
        meta: { title: 'm.new.menus.message_center_text', icon: 'personalIcon', hidden: false }
      },
      {
        path: 'view',
        name: 'ViewMessage',
        component: () => import('@/views/userCenter/personalCenter/view/index'),
        hidden: true,
        meta: { title: '消息详情', icon: 'personalIcon', activeMenu: '/admin/personalCenter/index' }
      },
      {
        path: 'reservation',
        name: 'ReservationPage',
        component: () => import('@/views/userCenter/personalCenter/reservation/index'),
        hidden: true,
        meta: { title: '对接预约', icon: 'personalIcon', activeMenu: '/admin/personalCenter/index' }
      }
    ]
  },
  */
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  }
]

export const asyncRoutes = [
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

//  新建路由
const createRouter = () => new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
  // scrollBehavior(to, from, savedPosition) {
  //   return new Promise(resolve => {
  //     if (savedPosition) {
  //       return savedPosition
  //     } else {
  //       // console.log('页面滚动到顶部。。。')
  //       resolve({ x: 0, y: 0 })
  //     }
  //   })
  // }
})

//  解决重复点击导航路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = createRouter()

//  重置路由
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
  console.log(router.matcher)
}

export default router
