<template>
  <div
    @mouseover="handleMouseover"
    @mouseout="handleMouseout"
    class="item-box"
  >
    <img :src="imgSrc">
    <span
      slot="title"
      :style="{
        fontSize: fontSize,
        paddingLeft: textLeft
      }"
    >
      {{ title }}
    </span>
    <span v-if="noticeNum" class="notice_">
      {{ noticeNum }}
    </span>
  </div>
</template>

<script>
//
import homeIcon from '@/assets/menu/home.png'
import homeIcon_Sel from '@/assets/menu/home_sel.png'
import recordIcon from '@/assets/menu/record.png'
import recordIcon_Sel from '@/assets/menu/record_sel.png'
import demandIcon from '@/assets/menu/demand.png'
import demandIcon_Sel from '@/assets/menu/demand_sel.png'
import supplyIcon from '@/assets/menu/supply.png'
import supplyIcon_Sel from '@/assets/menu/supply_sel.png'
import personalIcon from '@/assets/menu/personal.png'
import personalIcon_Sel from '@/assets/menu/personal_sel.png'

export default {
  name: 'MenuItem',
  // functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    activeMenu: {
      type: String,
      default: ''
    },
    level: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      homeIcon,
      homeIcon_Sel,
      recordIcon,
      recordIcon_Sel,
      demandIcon,
      demandIcon_Sel,
      supplyIcon,
      supplyIcon_Sel,
      personalIcon,
      personalIcon_Sel,
      isSelected: false,
      isHover: false,
      imageOptions: {
        'homeIcon': homeIcon,
        'homeIcon_Sel': homeIcon_Sel,
        'recordIcon': recordIcon,
        'recordIcon_Sel': recordIcon_Sel,
        'demandIcon': demandIcon,
        'demandIcon_Sel': demandIcon_Sel,
        'supplyIcon': supplyIcon,
        'supplyIcon_Sel': supplyIcon_Sel,
        'personalIcon': personalIcon,
        'personalIcon_Sel': personalIcon_Sel
      }
    }
  },
  computed: {
    imgSrc() {
      if (this.isSelected || this.isHover) {
        const key = this.icon + '_Sel'
        return this.imageOptions[key]
      }
      return this.imageOptions[this.icon]
    },
    fontSize() {
      if (this.level === 1) {
        // 一级
        return this.$language === 'cn' ? '15px' : '12px'
      } else {
        // 二级
        return this.$language === 'cn' ? '13px' : '11px'
      }
    },
    textLeft() {
      if (this.level === 2) {
        return '10px'
      }
    },
    // 未读消息数
    noticeNum() {
      if (this.activeMenu !== '/admin/personalCenter/index') {
        return 0
      }
      const num = this.$store.state.user.noticeNum
      if (num > 99) {
        return '99+'
      }
      return num
    }
  },
  created() {
    // console.log(this.$route)
    const meta = this.$route.meta
    if (this.$t(meta.title) === this.title || (meta.activeMenu && meta.activeMenu === this.activeMenu)) {
      this.isSelected = true
    } else {
      // alert(meta.title + '===' + this.title)
    }
  },
  methods: {
    // 鼠标移入
    handleMouseover() {
      // console.log('鼠标移入')
      this.isHover = true
    },
    // 鼠标移出
    handleMouseout() {
      // console.log('鼠标移出')
      this.isHover = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .item-box {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 56px;
    overflow: hidden;
    img {
      margin-right: 6px;
      width: 20px;
      height: auto;
    }
  }
  .item-box:hover {
    color: #ffffff;
    background: linear-gradient(106deg, #409EFF 0%, #004DD6 99%) !important;
  }
  .notice_ {
    margin-left: 10px;
    padding: 0 5px;
    color: #ffffff;
    min-width: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 13px;
    border-radius: 10px;
    background-color: #FF4E4E;
  }
</style>
