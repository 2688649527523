import { login, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import store from '@/store/index'
import { unreadNoticeNum } from '@/api/user'

const state = {
  //  用户信息
  userInfo: null,
  //  角色
  roles: [],
  //
  token: getToken(),
  // 未读消息数
  noticeNum: 0,

  sysUser: JSON.parse(sessionStorage.getItem('sysUser')),
  permissionList: JSON.parse(sessionStorage.getItem('permissionList'))
}

const mutations = {
  //  用户信息
  SET_SYS_USER: (state, userInfo) => {
    state.userInfo = userInfo
    state.sysUser = userInfo
    sessionStorage.setItem('sysUser', JSON.stringify(userInfo))
  },
  //  角色
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_PERMISSIONLIST: (state, permissionList) => {
    state.permissionList = permissionList
    sessionStorage.setItem('permissionList', JSON.stringify(permissionList))
  },
  SET_NOTICE_NUM: (state, num) => {
    state.noticeNum = num
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    // const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login(userInfo).then(response => {
        console.log(response)
        const data = response.data
        const tokenStr = data.tokenHead + data.token
        commit('SET_TOKEN', tokenStr)
        commit('SET_SYS_USER', data.user)
        commit('SET_PERMISSIONLIST', [])
        setToken(tokenStr)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        // console.log('个人信息-------------')
        // console.log(response)
        const data = response.data
        //  权限
        const roles = ['admin']
        if (data.permissionList) {
          data.permissionList.forEach(item => {
            if (item.perms) {
              if (roles.indexOf(item.perms) === -1) {
                roles.push(item.perms)
              }
            }
          })
        }
        commit('SET_ROLES', roles)
        commit('SET_SYS_USER', data)
        commit('SET_PERMISSIONLIST', data.permissionList ? data.permissionList : [])
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      // commit('SET_ROLES', ['admin'])
      if (!getToken()) {
        resolve()
      } else {
        commit('SET_ROLES', ['admin'])
        getInfo().then(response => {
          // console.log(response)
          const data = response.data
          // 角色
          commit('SET_ROLES', ['admin'])
          commit('SET_SYS_USER', data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      }
    })
  },
  // 未读消息数
  getUnreadNoticeNum({ commit }) {
    return new Promise(() => {
      unreadNoticeNum().then(res => {
        console.log('未读消息数为：' + res.data)
        commit('SET_NOTICE_NUM', res.data || 0)
      })
    })
  },
  // user logoutgetInfo
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_PERMISSIONLIST', [])

      commit('SET_SYS_USER', null)
      removeToken()
      resetRouter()
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },
  //
  setRoles({ commit }, roles) {
    return new Promise((resolve, reject) => {
      commit('SET_ROLES', roles)
      resolve(roles)
    })
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, roles) {
    return new Promise(async resolve => {
      // const token = role + '-token'
      //
      // commit('SET_TOKEN', token)
      // setToken(token)
      //
      // const { roles } = await dispatch('getInfo')

      resetRouter()
      // dispatch('permission/generateRoutes', roles, { root: true }).then()

      // generate accessible routes map based on roles
      // const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })

      // dynamically add accessible routes
      // router.addRoutes(accessRoutes)

      // reset visited views and cached views
      // dispatch('tagsView/delAllViews', null, { root: true })

      store.dispatch('user/setRoles', roles)
      store.dispatch('permission/generateRoutes', roles).then(res => {
        console.log('-------------')
        console.log(res)
        router.addRoutes(res)
      }).catch(err => {
        console.log(err)
      })

      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
