<template>
  <div style="margin-top: 100px;">
    <div class="slfk_footer">
      <div v-if="this.$language === 'cn'" class="footer_cont">
        <!-- 江苏省对外科技交流中心、江苏省跨国技术转移中心 -->
        <div>版权所有：中国—中东欧国家技术转移中心</div>
        <div>
          联系电话：+86-25-85485886&ensp;&ensp;
          邮 箱：ccttc@jittc.org&ensp;&ensp;
          备案号：<a href="https://beian.miit.gov.cn" target="_blank">苏ICP备09020522号-4</a>&ensp;&ensp;
          <img class="beian_icon" src="./images/beian_icon.png">
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32010202011593"
            target="_blank"
          >苏公网安备32010202011593号</a>
        </div>
      </div>
      <div v-else class="footer_cont">
        <div>Copyright 2023 CITTC - China-CEEC Technology Transfer Centre</div>
        <div>
          TEL: +86-25-85485886&ensp;&ensp;
          E-Mail: ccttc@jittc.org&ensp;&ensp;
          备案号: <a href="https://beian.miit.gov.cn" target="_blank">苏ICP备09020522号-4</a>&ensp;&ensp;
          <img class="beian_icon" src="./images/beian_icon.png">
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32010202011593"
            target="_blank"
          >苏公网安备32010202011593号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//
export default {

}
</script>

<style lang="scss" scoped>
  .slfk_footer {
    width: 100%;
    min-width: 1200px;
    height: 100px;
    background-color: #000000;
    .footer_cont {
      /*width: 600px;*/
      margin: 0 auto;
      text-align: center;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 12px;
        line-height: 25px;
        a {
          color: #ffffff;
          cursor: pointer;
          text-decoration: none;
        }
      }
      div:nth-child(1) {
        padding-top: 28px;
        text-align: center;
      }
      .beian_icon {
        /*margin: 0 10px;*/
        margin-right: 4px;
        width: auto;
        height: 18px;
      }
    }
  }
</style>
