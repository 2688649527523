import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
  m: {
    title: 'CHINA-CEEC TECHNOLOGY TRANSFER CENTRE',
    user: {
      login_text: 'Login',
      register_text: 'Register',
      center_text: 'Personal Center'
    },
    navbar: {
      title: 'Welcome to China-CEEC Technology Transfer Centre!',
      web_home: 'Home',
      language_cn: '简体中文',
      language_en: 'English',
      home: 'Home',
      about_us: 'About Us',
      center_dynamic: 'News Center',
      notice_title: 'Conference Information',
      cooperate_info: 'Cooperation Information',
      ceec_cooperate_text: 'CEEC Cooperation Requirement',
      china_cooperate_text: 'CHINA Cooperation Requirement',
      project_cooperate_text: 'Successful Cases',
      ceec_tech_supply_text: 'CEEC Cooperation Supply',
      china_tech_supply_text: 'CHINA Cooperation Supply',
      innovation_institution_text: 'Innovation Institution',
      technical_record: 'S&T Profiles',
      link_us: 'Contact Us',
      logout_text: 'Logout'
    },
    //  关于我们
    aboutus: {
      frame_text: 'Background',
      virtual_center: '虚拟"16+1合作"技术转移中心',
      manifesto_text: 'Mission',
      accessory_text: 'Accessory',
      can_download: 'Download',
      service_text: 'Service:'
    },
    //  合作信息
    hezuo: {
      keyword_text: 'Keyword: ',
      demand_name: 'Demand Name: ',
      organization_text: 'Organization: ',
      industry_title: 'Industry',
      country_title: 'Country',
      all_text: 'All',
      country_text: 'Country: ',
      release_time: 'Release time: ',
      industry_text: 'Industry: ',
      contact_text: 'Contact: ',
      tel_text: 'Tel: '
    },
    //  科技资讯
    technical: {
      file_size: 'File Size',
      update_time: 'Upload Time',
      data_download: 'Download'
    },
    //  联系我们
    linkus: {
      message_center: '江苏省跨国技术转移中心',
      city_text: '斯洛伐克共和国首都布拉迪斯拉发市',
      street_text: '拉马奇斯卡大街8/A号',
      postcode_text: '邮编',
      tel_text: '电话',
      email_text: '电子邮件',
      staff_number: '人员识别号',
      tax_number: '增值税识别号',
      register_number: '在斯洛伐克国库注册的账号'
    },
    cooperate_country: 'Cooperate Country',
    more_text: 'More',

    // 新的
    new: {
      account_ph: 'Please enter your email account',
      account_info: 'Fill in account information',
      unit_info: 'Fill in unit information',
      mailbox: 'Email:',
      password_v: 'At least 8 characters, at least 1 numeric character, at least 1 lowercase letter, at least 1 uppercase letter, at least 1 special character',
      businessname_ph: 'Please enter.',
      china_ph: '请中方参会代表填写',
      company_js: 'Describing your business:',
      company_ph: 'Please enter.',
      introduce_ph: 'Focus on fields, expertise and areas of strength. Text length is limited to 2,000 characters.',
      businessaddress_ph: 'Please enter.',
      phone_text: 'Contact Mobile:',
      phone_ph: 'Please enter.',
      back_ph: 'Last step',
      name_ph: 'Firstname:',
      surname_ph: 'Surname',
      province_ph: 'Please select your province or city',
      demand_text: 'Technical requirement name',
      demand_ph: 'Please enter a technical requirement name',
      contacts_text: 'Contact',
      contacts_ph: 'Please enter contact person',
      contacts_phone: 'Contact Mobile',
      contacts_phone_ph: 'Please enter the contact number',
      language_text: 'Language',
      language_ph: 'Please select a publishing language',
      desc_text: 'Intro',
      desc_ph: 'Please enter the technical requirements brief',
      nation_ph: 'Please select a country',
      sub_ph: 'Submit',
      demand_tab: 'Cooperation Requirements List',
      demand_con: 'All technical requirements that have been submitted for review are communicated to the organization through the publication of technical requirements.',
      key_word: 'Key Words:',
      key_word_ph: 'Requirement name/Contact',
      search_ph: 'Search',
      reset_ph: 'Reset',
      demand_add: 'New +',
      ti_time: 'Creation time',
      demand_edit: 'Edit',
      businessintype_text: 'Industry Classification',
      demand_add_text: 'New technical requirements',
      demand_edit_text: 'Edit technical requirements',
      back: 'Back',
      tinymce_ph: 'Please enter the content...',
      supply_tab: 'Technology supply list',
      supply_con: 'All technical supplies submitted for review inform the organization of the capabilities it can provide by publishing technical supplies.',
      supply_key: 'Supply name/Contact',
      supply_text: 'Technical supply name',
      shenh_ph: 'Audit Status:',
      supply_ph: 'Please fill in the technical supply name',
      supply_add: 'New +',
      supply_add_page: 'New technology supply',
      supply_edit_page: 'Edit technology supply',
      view_ph: 'View',
      supply_name: 'Supply name',
      sub_pass: 'Submit',
      code_ph: 'Please enter the verification code',
      new_pwd: 'New password',
      unit_info_text: 'Unit Information',
      edit_text: 'Edit',
      submit_info_text: 'Submit',
      company_name_text: 'Organization',
      message_ph: 'Message Notification',
      message_text: 'Message details',
      title_text: 'Title',
      status_text: 'Status',
      time_text: 'Time',
      operate_text: 'Operate Successfully',
      technical_requirements_details: 'Technical requirements details',
      technical_supply_details: 'Technical supply details',
      unit_type_text: 'Organization Type',
      chat_message_text: 'Chat Message',
      please_inter_text_ph: 'Please enter content',
      docking_appointment_text: 'Make Appointment',
      send_message_text: 'Send Message',
      read_text: 'Read',
      unread_text: 'UnRead',
      apply_unit_info_text: 'Unit Information',
      unit_demand_text: 'Unit demand',
      purpose_of_attendance: 'Purpose of Attendance:',
      apply_intro_text: 'Please fill in your cooperation needs (or) need to solve the technical problems',
      apply_intro_remark_text: 'Please fill in your cooperation requirements and/or technical problems to be solved in English.',
      apply_sd_remark_text: 'Agree that he (she) person through the published cooperation needs to connect.',
      docking_date_text: 'Docking Date:',
      apply_unit_text: 'Matchmaking',
      my_schedule_text: 'My Schedule',
      apply_info_text: 'Information',
      apply_success_text: 'Registration information submitted successfully please wait patiently for review!',
      home_open_type_text: 'Open Butt Joint',
      collaboration_is_easier: '"Make Collaboration Easier"',
      go_view_demand_text: 'Cooperation Demand',
      go_view_supply_text: 'Go View Supply',
      my_docking_text: 'My Schedule',
      matching_item_text: 'Matchmaking',
      //--------------------------
      cooperation_requirement_name: 'Cooperation Requirement Name(Chinese)',
      cooperation_requirement_name_en: 'Cooperation Requirement Name(English)',
      cooperation_requirement_name_ph: 'Please fill in the name of the cooperation requirement',
      contact_name: 'Contact(Chinese)',
      contact_name_en: 'Contact(English)',
      contact_name_ph: 'Please fill in the contact',
      telephone_text: 'Telephone',
      cooperation_requirements_description: 'Cooperation Requirements Description(Chinese)',
      cooperation_requirements_description_en: 'Cooperation Requirements Description(English)',
      ideal_way_of_cooperation: 'Ideal Way of Cooperation(Chinese)',
      ideal_way_of_cooperation_en: 'Ideal Way of Cooperation(English)',
      menus: {
        personal_center_text: 'Personal Center',
        convention_activity_text: 'Upcoming Conference',
        normal_docking_text: 'Daily Matchmaking',
        profile_text: 'Personal Information',
        cooperate_text: 'Cooperation Requirement',
        cooperation_demand_text: 'Cooperation Demand',
        cooperation_supply_text: 'Cooperation Supply',
        message_center_text: 'Notification Center'
      }
    },
    login_page_name: 'Welcome to China-CEEC Technology Transfer Centre！',
    name: 'Customer Center',
    check_failure: 'Verification failed. Please check the information.',
    validate_normal_text: 'This item is required',
    validate_include_chinese: 'This item cannot contain Chinese characters.',
    new_text: 'Add',
    new_success: 'Add Success',
    delete_text: 'Delete',
    save_text: 'Save',
    save_success: 'Save Success',
    handle_text: 'Operation',
    prompt_text: 'Prompt',
    sure_text: 'Confirm',
    cancel_text: 'Cancel',
    continue_text: 'Continue',
    delete_success: 'Delete Success',
    submit_success_text: 'Submit Success',
    search_filters: 'Search filters',
    search_text: 'Search',
    reset_text: 'Reset',
    logout_text: 'Log Out',
    password_changed_successfully_text: 'Password changed successfully',
    prompt_before_text: 'This operation will delete「',
    prompt_after_text: '」,do you want to continue?',
    prompt_sure_delete_text: 'Are you sure to delete this message?',

    //  状态
    waiting_review: 'Reviewing',
    already_passed: 'Accepted',
    not_pass: 'Rejected',
    normal_text: 'Normal',
    lose_efficacy: 'Invalid',
    have_deleted: 'Deleted',
    have_checked: 'Checked',

    //  顶部
    top_title: 'Welcome to China-CEEC Technology Transfer Centre！',
    login_text: 'Sign in',
    register_text: 'Register',
    website_homepage: 'Home',
    back_homepage: 'Back Home',

    //  登录页面
    back_home: 'Return Home',
    user_login: 'Login',
    account_text: 'Email:',
    account_ph: 'E-mail',
    password_text: 'Password:',
    password_ph: 'Please enter password',
    forget_text: 'Forget password?',
    remember_password: 'Remerber',
    register_immediately: 'Register',
    remember_remark: 'Record password function should not be used in public places (such as Internet cafe, etc.) to prevent password leakage. Are you sure you want to use this feature?',

    //  注册
    user_regist: 'User Registration',
    businessemail_text: 'E-mail:',
    businessemail_ph: 'Please enter the email address of your company',
    businessemail_remark: 'The activation code will be sent to this account.',
    confirm_password: 'Re-enter Password:',
    confirm_password_ph: 'Please enter your confirmation password',
    businessnamechina_text: 'Organization(Chinese):',
    businessnamechina_ph: 'Please enter unit name (Chinese)',
    businessname_text: 'Organization(English):',
    businessname_ph: 'Please enter unit name (English)',
    businesstype_text: 'Organization Type:',
    businesstype_ph: 'Select the type of organization',
    businessintype_text: 'Industry Classification:',
    first_businessintype_text: 'Please select first industry classification',
    second_businessintype_text: 'Please select second industry',
    businessintype_more: 'Choose five categories at most',
    country_text: 'Country/Area:',
    select_place: '-Please select-',
    businessno_text: 'Unified Social Information Code:',
    businessno_ph: 'Please enter the Uniform Social Information Code',
    businesscardphotourl_text: 'Business License Photo/Organization Code Photo:',
    businesscardphotourl_remark0: '(Photos support JPG, PNG format and size of no more than 3MB)',
    businesscardphotourl_remark1: 'Upload pictures from your computer',
    businessaddress_text: 'Address(Chinese):',
    businessaddress_ph: 'Please enter the detailed address (in Chinese)',
    businessaddressEnglish_text: 'Address(English):',
    businessaddressEnglish_ph: 'Please enter the detailed address (in English)',
    postcode_text: 'Zip Code:',
    postcode_ph: 'Please enter a postcode',
    cname_text: 'Contact(Chinese):',
    cname_ph: 'Please enter the company contact person (Chinese)',
    cnameEnglish_text: 'Contact(English):',
    cnameEnglish_ph: 'Please enter the company contact person (English)',
    linkemail_text: 'Email:',
    linkemail_ph: 'Please enter contact email',
    telephone_text: 'Mobile:',
    telephone_ph: 'Please enter your mobile phone number',
    code_text: 'Activation Code:',
    input_code_please: 'Please enter the verification code',
    input_please: 'Please input',
    referees_text: 'Referees:',
    read_and_accept: 'I have read and agreed to JITTC Terms of Use',
    protocol_name: '《The Terms of Use Of China-CEEC Technology Transfer Centre》',
    submit_regist: 'Submit',
    sure_submit: 'Submit',
    send_code_text: 'Get verification',
    regist_success: 'Registered successfully',
    email_format_fail: 'Incorrect format or length cannot exceed 30',
    email_format_used: 'This email has been registered.',
    org_name_existing: 'The organization name is already in use',
    resend_text: 'To resend',
    double_select_remark: 'You can\'t double select',
    purpose_of_attendance: 'Purpose of Attendance:',
    purpose_of_attendance_ph: ' Please elaborate in English your meeting request and/or cooperation intent.',

    //  找回密码
    retrieve_password: 'Reset Password',
    retrieve_Email: 'Email:',
    user_businessemail_ph: 'Client\'s mailbox',
    next_step: 'Next',
    email_verification: 'E-mail verification code:',
    new_login_pw: 'New login password:',
    new_login_pw_ph: 'Please enter your new login password',
    new_confirm_pw: 'Confirm the new password:',
    new_confirm_pw_ph: 'Please enter your new login password again',
    step_remark_3_0: 'The new password has been reset successfully and will be redirected to the login page after ',
    step_remark_3_1: 'second',

    //  修改密码
    edit_pw: 'Change Password',
    old_pwd: 'Old Password',
    old_pwd_ph: 'Please enter your old password',
    new_pwd: 'New Password',
    new_pwd_ph: 'Please enter a new password',
    new_pwd_remark: 'The new password is 6-20 digits',
    confirm_pwd: 'Repeat Password',
    confirm_pwd_ph: 'Please enter a new password again',
    confirm_pwd_remark: 'The duplicate new password is not consistent with the new password',

    //  首页
    perfect_information: 'Modify Profile',
    country_area: 'Country/Area:',
    i_publis_tec: 'My Technology Supply',
    preview_all_tec: 'View All Technology Supply',
    i_publis_demand: 'My Technology Demand',
    preview_all_demand: 'View All Technology Demand',

    //  单位资料
    unit_info: 'Organization Profile',
    basic_info: 'Basic Information',
    other_info: 'Other Information',
    unit_header: 'Headshot:',
    unit_header_remark: 'Upload pictures from your computer',
    unit_introduction: 'Organization Profile (Chinese):',
    unit_introduction_english: 'Organization Profile (English):',
    unit_url: 'Organization Website:',
    unit_url_ph: 'Please enter the organization website',
    unit_size: 'Size Of Organization:',
    unit_date: 'Establishment Time:',
    product_service: 'Product/Service（Chinese）:',
    product_service_ph: 'Please enter product/service (Chinese)',
    product_service_english: 'Product/Service（English）:',
    product_service_english_ph: 'Please enter product/service (English)',

    //  联系人
    contacts_manager: 'Contacts Management',
    number_text: 'No.',
    contacts_add: 'Add Contact',
    contacts_edit: 'Edit Contact',
    contacts_preview: 'Contact Details',
    contacts_name: 'Contact Name(Ch)',
    contacts_name_ph: 'Please enter contact name (Ch)',
    contacts_name_en: 'Contact Name(En)',
    contacts_name_en_ph: 'Please enter contact name (En)',
    contacts_telephone: 'Mobile',
    contacts_telephone_ph: 'Please enter your contact number',
    contacts_email: 'Email',
    job_text: 'Position(Ch)',
    job_text_ph: 'Please enter position (Ch)',
    job_text_en: 'Position(En)',
    job_text_en_ph: 'Please enter position (En)',
    fax_text: 'Fax',
    fax_tex_ph: 'Please enter fax',
    contacts_status: 'Contact State',
    data_status: 'Profile Status',
    not_available: 'No',
    delete_row: 'Select the row to delete!',
    contacts_delete_remind: 'This action will permanently delete the contact, whether to continue?',

    //  技术需求
    perfect_information_remark: 'Hello! Please complete your organization information first.',
    technical_demand: 'Technology Demand',
    demand_preview: 'Demand Details',
    demand_edit: 'Edit Demand',
    demand_publish: 'Release Demand',
    demand_name: 'Technology Name',
    demand_name_ph: 'Please enter a technical name',
    first_classify: 'Industry Ⅰ',
    second_classify: 'Industry Ⅱ',
    publish_time: 'Release Time',
    unit_contacts: 'Contact',
    center_contacts: '中心负责人',
    check_status: 'Status',
    usable_text: '可用',
    disabled_text: '不可用',
    demand_delete_remind: 'This action permanently removes this technical requirement. Do you want to continue?',
    demand_delete_remind1: 'This permanently deletes the selected technical requirement. Do you want to continue?',
    introduction_text: 'Introduction',
    illustration_text: 'Illustration',
    introduction_input_ph: 'Kindly Reminder: Please use Verdana, 14 when uploading text.',

    //  技术供给
    technical_supply: 'Technology Supply',
    technical_publish: 'Release Technology',
    technical_preview: 'Technology Details',
    nation_text: 'Country',
    technical_edit: 'Edit Technology',
    technical_name: 'Technology Name',
    technical_name_ph: 'Please enter a technical name',
    technical_type: 'Industry',
    supply_delete_remind: 'This action will permanently delete this technical feed. Do you want to continue?',
    supply_delete_remind1: 'This permanently deletes the selected technical feed. Do you want to continue?',
    enter_meeting_text: 'Meeting',
    //
    submit_success: 'Submit Successfully',
    action_text: 'Action',
    dock_info_text: 'Matchmaking Information',
    send_text: 'Send',
    table_number: 'Table No'
  },
  ...enLocale
}
export default en
