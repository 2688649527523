<template>
  <div class="navbar-box">
    <div class="navbar">
      <img
        class="web-logo"
        src="../../assets/cct-logo.png"
        @click="$router.push({ path: '/home' })"
      >
      <img class="tmp-logo" src="../images/tmp-logo.png">
      <div v-if="sysUser" class="right-box">
        <!-- 语言 -->
        <div class="location-box">
          <img src="../../assets/images/nav/location_icon.png">
          <span
            :style="{'color': $language === 'cn' ? '#409EFF' : '#666666'}"
            @click="handleSwitchLocation"
          >中</span>
          <span style="padding: 0 2px;">/</span>
          <span
            :style="{'color': $language !== 'cn' ? '#409EFF' : '#666666'}"
            @click="handleSwitchLocation"
          >English</span>
        </div>
        <!-- 消息 -->
        <div class="msg-box" @click="$router.push({ path: '/admin/personalCenter' })">
          <img src="../../assets/images/nav/msg_icon.png">
          <span v-if="showMsg" class="red_" />
        </div>
        <!-- 用户信息 -->
        <el-dropdown trigger="click" @command="handleCommand">
        <span style="cursor: pointer;">
          {{ sysUser.businessemail }}<i class="el-icon-caret-bottom el-icon--right" />
        </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">
              <span style="padding: 0 10px;">{{ $t('m.navbar.logout_text') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <img class="water_mark_left" src="../images/water_mark_left.png">
      <img class="water_mark_right" src="../images/water_mark_right.png">
      <div class="line" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
// import { getCxt, removeCxt } from '@/socket-client'
export default {
  components: {
    Breadcrumb,
    Hamburger
  },
  data() {
    return {
      showMsg: false
    }
  },
  computed: {
    ...mapGetters([
      'sysUser',
      'sidebar',
      'device'
    ]),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
  created() {
    document.title = this.$t('m.title')
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      this.$store.dispatch('user/getInfo').then(res => {})
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    //  切换语言
    handleSwitchLocation() {
      if (this.$language === 'cn') {
        // 当前为中文网站跳转英文网站
        const url =  process.env.VUE_APP_EN_WEA_URL + '/#/admin/home/index'
        window.location.href = url
      } else {
        // 当前为英文网站跳转中文网站
        const url =  process.env.VUE_APP_CN_WEA_URL + '/#/admin/home/index'
        window.location.href = url
      }
    },
    handleCommand(command) {
      if (command === 'logout') {
        this.handleLogout()
      }
    },
    //  登出
    async handleLogout() {
      console.log('handleLogout...1')
      await this.$store.dispatch('user/logout')
      console.log('handleLogout...2')
      this.$router.push({ path: '/login' })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/variables.scss";
  .navbar-box {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1001;
    /*box-sizing: content-box;*/
    background-color: #ffffff;
  }
  .navbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 1400px;
    padding: 0 38px;
    height: 80px;
    /*overflow: hidden;*/
    box-shadow: 0px 5px 10px 0px rgba(7, 69, 134, 0.1);
    /*border-bottom: 10px solid #E8E9EB;*/
    .line {
      position: absolute;
      right: 0;
      top: 80px;
      width: 1200px;
      height: 10px;
      z-index: -1;
      background-color: #E8E9EB;
    }
    .web-logo {
      margin-top: 6px;
      /*width: 304px;*/
      width: auto;
      height: 65px;
      cursor: pointer;
    }
    .tmp-logo {
      height: 36px;
      width: auto;
    }
    .water_mark_left {
      position: absolute;
      left: 280px;
      bottom: -6px;
      z-index: -1;
      height: 44px;
      width: auto;
    }
    .water_mark_right {
      position: absolute;
      right: -11px;
      z-index: -1;
      height: 80px;
      width: auto;
      /*border: 1px solid red;*/
    }
    .tmp-logo-box {
      display: flex;
      align-items: center;
      padding-left: 40px;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      img {
        width: 62px;
        height: 62px;
      }
      .text-box {
        display: flex;
        flex-direction: column;
        padding-left: 16px;
        span {
          color: #000000;
        }
        .en {
          padding-top: 2px;
          font-family: Verdana;
          letter-spacing: 1px;
        }
      }
    }

    .right-box {
      float: right;
      display: flex;
      align-items: center;
      height: 100%;
      .location-box {
        display: flex;
        align-items: center;
        margin-right: 25px;
        color: #333333;
        img {
          margin-right: 3px;
          width: 30px;
          height: auto;
        }
        span {
          cursor: pointer;
        }
      }
      .msg-box {
        position: relative;
        margin-right: 35px;
        cursor: pointer;
        img {
          width: 27px;
          height: auto;
        }
        .red_ {
          position: absolute;
          top: 2px;
          right: 4px;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #FF3535;
        }
      }
    }

    .hamburger-container {
      line-height: 46px;
      height: 100%;
      float: left;
      cursor: pointer;
      transition: background .3s;
      -webkit-tap-highlight-color:transparent;

      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }

    .breadcrumb-container {
      float: left;
    }

    .errLog-container {
      display: inline-block;
      vertical-align: top;
    }

    .right-menu {
      display: flex;
      flex-direction: row-reverse;
      height: 100%;
      line-height: 50px;

      &:focus {
        outline: none;
      }

      .right-menu-item {
        display: inline-block;
        padding: 0 8px;
        height: 100%;
        font-size: 18px;
        color: #5a5e66;
        vertical-align: text-bottom;

        &.hover-effect {
          cursor: pointer;
          transition: background .3s;

          &:hover {
            background: rgba(0, 0, 0, .025)
          }
        }
      }

      .avatar-container {
        margin-right: 10px;
        font-size: 14px;

        .avatar-wrapper {
          position: relative;
          display: flex;
          align-items: center;

          .user-avatar {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .locale_item {
    display: flex;
    padding-right: 10px;
    .locale-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
</style>
