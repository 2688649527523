<template>
  <div
    v-if="!item.hidden"
    class="menu-wrapper"
    :class="{'menu-wrapper-border': isActive}"
  >
    <template v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren)">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)" style="text-decoration: none;">
        <el-menu-item :index="resolvePath(onlyOneChild.path)">
          <div class="title_text">
            {{ onlyOneChild.meta.title }}
          </div>
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu v-else :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title">
        <div style="width: 100px;">{{ item.meta.title }}</div>
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
      />
    </el-submenu>
  </div>
</template>

<script>
//
import path from 'path'
import AppLink from './Link'
import { isExternal } from '@/utils/validate'
export default {
  name: 'SidebarItem',
  components: {
    AppLink
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    this.onlyOneChild = null
    return {
      isActive: false
    }
  },
  watch: {
    $route(nowRouter) {
      if (this.item.children) {
        const path = this.basePath + '/'
        if (nowRouter.path === '/home' && this.basePath === '/') {
          this.isActive = true
        } else if (nowRouter.path.indexOf(path) !== -1) {
          this.isActive = true
        } else {
          this.isActive = false
        }
      }
    }
  },
  created() {
    if (this.item.children) {
      // console.log(this.basePath)
      const path = this.basePath + '/'
      // console.log(this.$route.path)
      if (this.$route.path === '/home' && this.basePath === '/') {
        this.isActive = true
      } else if (this.$route.path.indexOf(path) !== -1) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    }
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          this.onlyOneChild = item
          // console.log(this.onlyOneChild)
          // console.log(this.resolvePath(this.onlyOneChild.path))
          return true
        }
      })
      // if (this.onlyOneChild) {
      //   console.log(this.onlyOneChild)
      //   console.log(showingChildren)
      // }
      if (showingChildren.length === 1) {
        return true
      }
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ... parent, path: '', noShowingChildren: true }
        return true
      }
      return false
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>

<style lang="scss" scoped>
  .menu-wrapper {
    height: 56px;
    overflow: hidden;
  }
  .menu-wrapper-border {
    border-bottom: 2px solid #409EFF;
  }
  .title_text {
    width: 100px;
    font-size: 16px;
    text-align: center;
    font-weight: bolder;
    background-color: #fff;
  }
  /deep/ .el-submenu__title {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  /deep/ .el-menu-item {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
</style>
