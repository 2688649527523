<template>
  <div
    id="app"
    :class="[activeClass]"
  >
    <router-view :key="key" />
  </div>
</template>

<script>
//
export default {
  data() {
    return {
      language: this.$language
    }
  },
  computed: {
    key() {
      return this.$route.fullPath
    },
    activeClass() {
      if (this.$language === 'cn') {
        return 'font_cn'
      } else {
        return 'font_en'
      }
    }
  },
  created() {
    //
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: #333;
  min-width: 1200px;
  overflow-y: auto;
  /*font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;*/
}
#app {
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}
  .font_cn {
    font-size: 13px;
    font-family: Microsoft YaHei;
    /*font-family: "Times New Roman";*/
  }
  .font_en {
    font-size: 14px;
    font-family: Verdana , Geneva , sans-serif;
  }
</style>
