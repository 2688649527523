import Cookies from 'js-cookie'

const TokenKey = 'KJT-MANAGER-TOKEN'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 7 })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

const LocaleKey = 'CCEEC-Web-Locale'
export function getLocale() {
  return Cookies.get(LocaleKey)
}

export function setLocale(locale) {
  return Cookies.set(LocaleKey, locale, { expires: 90 })
}

export function removeLocale() {
  return Cookies.remove(LocaleKey)
}
