import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

import { getToken } from '@/utils/auth' // get token from cookie

const whiteList = [
  '/login',
  '/regist',
  '/forget',
  '/home',
  '/dynamic',
  '/dynamicDetails',
  '/aboutus',
  '/linkus',
  '/technical',
  '/country',
  '/dynamic/list',
  '/dynamic/details',
  '/cooperate/ceec',
  '/cooperate/china',
  '/cooperate/tech/supply/ceec',
  '/cooperate/tech/supply/china',
  '/cooperate/project',
  '/cooperate/innovation',
  '/cooperate/details',
  '/dynamic/noticeDetails',
  '/dynamic/notice'
]

router.beforeEach(async(to, from, next) => {
  NProgress.start()
  // removeToken()
  const hasToken = getToken()
  if (hasToken) {
    if (to.query.type === 'logout') {
      logout()
    } else if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      // console.log('--------------', store.getters.roles)
      if (hasRoles) {
        // console.log('==============')
        next()
      } else {
        // console.log('++++++++++++++++')
        store.dispatch('user/getUserInfo').then(() => {
          const roles = store.getters.roles
          store.dispatch('permission/generateRoutes', roles).then(res => {
            router.addRoutes(res)
            next({ ...to, replace: true })
          })
        })
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})


/*
router.beforeEach(async(to, from, next) => {
  NProgress.start()
  // next()

  const hasRoles = store.getters.roles && store.getters.roles.length > 0
  if (hasRoles) {
    next()
  } else {
    store.dispatch('user/getUserInfo').then(() => {
      const roles = store.getters.roles
      store.dispatch('permission/generateRoutes', roles).then(res => {
        res.forEach(route => {
          router.addRoute(route)
        })
        next({ ...to, replace: true })
      })
    })
  }


  // const token = getToken()
  // if (token) {
  //   //  已登录
  //   if (to.path === '/login') {
  //     next({ path: '/' })
  //   } else {
  //     const hasRoles = store.getters.roles && store.getters.roles.length > 0
  //     if (hasRoles) {
  //       next()
  //     } else {
  //       store.dispatch('user/getUserInfo').then(() => {
  //         const roles = store.getters.roles
  //         store.dispatch('permission/generateRoutes', roles).then(res => {
  //           // router.addRoutes(res)
  //           res.forEach(route => {
  //             router.addRoute(route)
  //           })
  //           next({ ...to, replace: true })
  //         })
  //       })
  //     }
  //   }
  // } else {
  //   //  未登录
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     //  未登录白名单
  //     next()
  //   } else {
  //     next('/login')
  //   }
  // }
})

router.afterEach(() => {
  NProgress.done()
})

function logout() {
  var url = ''
  if (getLocale() === 'cn') {
    url = 'https://www.jittc.org/browse/user/logout'
  } else {
    url = 'https://en.jittc.org/litejsen/browse/enUser/logout'
  }
  axios.get(url).then(async() => {
    await store.dispatch('user/logout')
    router.push({ path: '/login' })
  }).catch(() => {
    store.dispatch('user/logout').then(() => {
      router.push({ path: '/login' })
    })
  })
}
*/
