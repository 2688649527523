<template>
  <!--class="center-font-wrapper"-->
  <div
    style="padding-left: calc(100vw - 100%);"
  >
    <!--:style="{transform: `scale(${scale})`}"-->
    <div class="app-wrapper">
      <navbar />
      <sidebar class="sidebar-container" />
      <app-main />
      <!--<div class="main-container">-->
      <!--<app-main />-->
      <!--</div>-->
    </div>
  </div>
</template>
<script>
//
import { AppMain, Navbar, Sidebar } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'
export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    Sidebar
  },
  mixins: [ResizeMixin],
  data() {
    return {
      scale: 1
    }
  },
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
  created() {
    this.$store.dispatch('user/getUnreadNoticeNum')
  },
  mounted() {
    window.addEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      const percent = document.documentElement.clientWidth / 1400
      this.scale = percent > 1 ? 1 : percent
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/mixin.scss";
  /*@import "~@/styles/variables.scss";*/
  .app-wrapper {
    //@include clearfix;
    position: relative;
    margin: 0 auto;
    padding-bottom: 90px;
    max-width: 1400px;
    min-height: 100%;
    /*min-height: 100vh;*/
    /*overflow: hidden;*/
    background-color: #E8E9EB;
  }
</style>
