export default {
  install(Vue) {
    Vue.prototype.abc = {
      name: 'xiaofeng'
    }
    /**
     * 存储localStorage
     * @param {String} name
     * @param content
     */
    Vue.prototype.setStorage = (name, content) => {
      if (!name) return
      if (typeof content !== 'string') {
        content = JSON.stringify(content)
      }
      window.localStorage.setItem(name, content)
    }

    /**
     * 获取localStorage
     * @param {String} name
     * @returns {*}
     */
    Vue.prototype.getStorage = name => {
      if (!name) return
      const data = window.localStorage.getItem(name)
      try {
        return JSON.parse(data)
      } catch (e) {
        return data
      }
    }

    /**
     * 删除localStorage
     * @param {String} name
     */
    Vue.prototype.removeStorage = name => {
      if (!name) return
      window.localStorage.removeItem(name)
    }

    /**
     * 清空本地localStorage所有存储数据
     */
    Vue.prototype.clearStore = () => {
      window.localStorage.clear()
    }
  }
}
